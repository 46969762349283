import { Box } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { DownSort, NoSort, UpSort } from "./icons";

const Sorting = ({ stateKey, sortKey, stateDesc = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ ml: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
      {stateKey === sortKey && stateDesc === true && (
        <DownSort color={colors.white[900]} width={"15px"} height={"15px"} />
      )}
      {stateKey === sortKey && stateDesc === false && (
        <UpSort color={colors.white[900]} width={"15px"} height={"15px"} />
      )}
      {stateKey !== sortKey && <NoSort width={"15px"} height={"15px"} color={colors.grey[700]} />}
    </Box>
  );
};

export default Sorting;
