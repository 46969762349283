import { useTheme } from "@emotion/react";
import { useState } from "react";
import MyDialog from "./MyDialog";
import { NavigateNextIcon, PDFFillIcon } from "./icons";
import { IconButton } from "@mui/material";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function ImageGallery({ type, isViewerOpen, files = [], setIsViewerOpen }) {
  const { palette } = useTheme();
  const [slideIndex] = useState(isViewerOpen?.active);

  let images =
    files?.length > 0 &&
    files.map((e) => {
      if (type === "messageBoxView") {
        return `${URL.createObjectURL(e)}`;
      } else if (type === "textBoxView") {
        return `${baseUrl}/${e.fileUrl}`;
      } else {
        return e;
      }
    });

  const nextSlide = () => {
    if (isViewerOpen?.active !== images.length) {
      setIsViewerOpen((prev) => {
        return { ...prev, active: isViewerOpen?.active + 1 };
      });
    } else if (isViewerOpen?.active === images.length) {
      setIsViewerOpen((prev) => {
        return { ...prev, active: 1 };
      });
    }
  };

  const prevSlide = () => {
    if (isViewerOpen?.active !== 1) {
      setIsViewerOpen((prev) => {
        return { ...prev, active: isViewerOpen?.active - 1 };
      });
    } else if (slideIndex === 1) {
      setIsViewerOpen((prev) => {
        return { ...prev, active: images.length };
      });
    }
  };

  return (
    <MyDialog
      maxWidth="md"
      open={isViewerOpen.open}
      handleClose={() => setIsViewerOpen({ open: false, active: 1 })}
      sx={{
        "& .MuiDialog-container .MuiPaper-root": {
          margin: 0,
          maxWidth: "100%",
          width: "100%",
          background: "#0006",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          "& .MuiButtonBase-root": {
            background: "#fff"
          }
        }
      }}
    >
      <div className="container-slider">
        {images.length > 0 &&
          images?.map((obj, index) => {
            return obj.type === "application/pdf" || obj.contentType === "application/pdf" ? (
              <a
                key={index}
                href={obj}
                target="_blank"
                rel="noreferrer"
                className={isViewerOpen?.active === index + 1 ? "slide active" : "slide"}
              >
                <PDFFillIcon width="100px" height="100px" color={palette.background.default} />
              </a>
            ) : (
              <div key={index} className={isViewerOpen?.active === index + 1 ? "slide active" : "slide"}>
                <img src={obj} alt="carosel docs" />
              </div>
            );
          })}
        {images.length > 1 && (
          <>
            <IconButton
              className="slider-btn next"
              disabled={images.length <= 1}
              onClick={nextSlide}
              sx={{ background: `${palette.mode === "light" ? "#F5F5F7" : "#282c3a"} !important` }}
            >
              <NavigateNextIcon color={palette.primary.main} />
            </IconButton>
            <IconButton
              className="slider-btn prev"
              disabled={images.length <= 1}
              onClick={prevSlide}
              sx={{ background: `${palette.mode === "light" ? "#F5F5F7" : "#282c3a"} !important` }}
            >
              <NavigateNextIcon color={palette.primary.main} />
            </IconButton>
          </>
        )}
      </div>
    </MyDialog>
  );
}
