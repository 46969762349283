import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";

const TableLoader = ({ cols = [] }) => {
  return (
    <TableBody>
      {Array.from(Array(5).keys())?.map((y) => {
        return (
          <TableRow key={y}>
            {Array?.from(Array(cols?.filter((x) => !x?.hide)?.length)?.keys())?.map((x) => {
              return (
                <TableCell key={x}>
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableLoader;
