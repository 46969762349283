import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import Sorting from "../Sorting";

const TableHeading = ({ cols, manualColor, colors, dataFilter, setDataFilter }) => {
  const handleSort = (item) => () => {
    if (dataFilter.key !== item) {
      setDataFilter({ ...dataFilter, key: item, desc: true });
    } else {
      if (dataFilter.desc === true) {
        setDataFilter({ ...dataFilter, key: item, desc: false });
      } else {
        setDataFilter({ ...dataFilter, key: "_id", desc: true });
      }
    }
  };
  return (
    <TableHead
      className="table-header"
      sx={{
        // background: manualColor ? "#bcbaba" : colors.primary[800],
        position: "sticky",
        top: "0",
        zIndex: "99"
      }}
    >
      <TableRow>
        {cols
          ?.filter((x) => !x.hide)
          ?.map((headerItem, index) => {
            const isSticky = headerItem?.sticky;
            return (
              <TableCell
                key={index}
                className={isSticky ? "sticky-column" : ""}
                sx={{
                  color: colors.white[900] || "#ffffff",
                  p: 2,
                  minWidth: index === 0 ? "none" : "80px",
                  textWrap: "nowrap",
                  width: headerItem?.width ? headerItem?.width : "auto"
                }}
              >
                {headerItem.sortKey ? (
                  <Box sx={{ cursor: "pointer", display: "flex" }} onClick={handleSort(headerItem.sortKey)}>
                    {headerItem.title}
                    <Sorting sortKey={headerItem.sortKey} stateKey={dataFilter.key} stateDesc={dataFilter.desc} />
                  </Box>
                ) : (
                  headerItem.title
                )}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeading;
