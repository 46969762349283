import * as React from "react";
import Select from "react-select";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Grid,
  FormHelperText,
  FormControl,
  useTheme,
  Tooltip,
  IconButton,
  Checkbox,
  InputAdornment,
  useMediaQuery,
  Typography
} from "@mui/material";
import { styles } from "../../utils/custom";
import TreeViewSelect from "./TreeViewSelect";
import { addCategory, fetchCategories, getCategoryTree, getSpecificationsList, updateCategory } from "./redux/action";
import { useForm, Controller } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { GetDepth, getParentCategories, getParentSpecification } from "./getParentid";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import MyDialog from "../../components/MyDialog";
import ExitingValueFilter from "./ExitingValueFilter";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ImageClose from "../../components/ImageClose";
import Taxs from "./Taxs";
import SpecificationGRP from "./SpecificationGRP";
import RequiredFieldLabel from "../../components/RequiredFieldLabel";
import { isMaxLimitCross, validImages } from "../../utils/common";

export default function CategoryAdd({ open, handleClose, reqData, rowData, isCategoryFromProduct }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [files, setFiles] = React.useState([]);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [continues, setContinues] = React.useState(false);
  const [parrentTax, setParrentTax] = React.useState([]);
  const [parrentSpecification, setParrentSpecification] = React.useState([]);

  const { common } = useSelector(
    (state) => ({
      common: state && state.categories && state.categories ? state.categories : []
    }),
    shallowEqual
  );

  const { isLoading, specifications } = common;

  const { categoriesTreeListTrue } = useSelector(
    (state) => ({
      categoriesTreeListTrue:
        state && state.categories && state.categories.categoriesTreeListTrue
          ? state.categories.categoriesTreeListTrue
          : []
    }),
    shallowEqual
  );
  const { dropDownTaxes } = useSelector(
    (state) => ({
      dropDownTaxes: state && state.categories && state.categories.dropDownTaxes ? state.categories.dropDownTaxes : []
    }),
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(getCategoryTree({ flag: true }));
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getSpecificationsList());
  }, [dispatch]);

  // const [dropDownOption, setDropDownOption] = React.useState([]);
  // React.useEffect(() => {
  //   if (dropDownTaxes.length > 0) {
  //     setDropDownOption(
  //       dropDownTaxes?.map((x) => ({
  //         value: x._id,
  //         label: `${x.label}(${x.taxPercent}%)`
  //       }))
  //     );
  //   }
  // }, [dropDownTaxes]);

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: "",
      type: "",
      categoryId: "",
      taxId: [],
      specificationId: [],
      isAgeUp21: false,
      isReturnable: false,
      categoryImg: []
    }
  });
  // Display category tree
  const [categoryValue, setCategoryValue] = React.useState("");

  const getPCategories = (id, categoryData) => {
    if (id) {
      const parentCategories = getParentCategories(categoryData ? categoryData : categoriesTreeListTrue, id);
      const parentSpecification = getParentSpecification(categoryData ? categoryData : categoriesTreeListTrue, id);
      if (parentSpecification !== null) {
        setParrentSpecification(
          parentSpecification.reduce((acu, curr) => (acu = [...acu, ...curr?.specificationId]), [])
        );
      }
      if (parentCategories !== null) {
        setCategoryValue(parentCategories.reverse());
        const taxArray = parentCategories.reduce((acu, curr) => (acu = [...acu, ...curr?.taxId]), []);
        setParrentTax(taxArray);
        // if (taxArray.length > 0) {
        //   const newArray = dropDownTaxes.map((ele) => {
        //     const index = taxArray.findIndex((t) => t._id === ele._id);
        //     if (index >= 0) {
        //       return false;
        //     }
        //     return { value: ele._id, label: `${ele.label}(${ele.taxPercent}%)` };
        //   });
        //   // setDropDownOption(newArray.filter((x) => x));
        // }
      }
    }
  };

  // Reset form on close
  React.useEffect(() => {
    if (!rowData?._id && !open) {
      reset({
        name: "",
        type: "",
        categoryId: "",
        taxId: [],
        isAgeUp21: false,
        isReturnable: false
      });
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [rowData?._id]);

  React.useEffect(() => {
    if (rowData?._id) {
      getPCategories(rowData?.categoryData?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, categoriesTreeListTrue]);

  React.useEffect(() => {
    if (rowData?._id) {
      if (rowData.categoryImg && rowData.categoryImg.fileUrl) {
        setFiles([rowData.categoryImg]);
      }

      reset({
        name: rowData?.name,
        // specificationId:
        //   rowData?.specificationId &&
        //   rowData?.specificationId.length > 0 &&
        //   rowData.specificationId.map((x) => ({
        //     value: x._id,
        //     label: x.name
        //   })),
        type: rowData?.categoryData?._id !== "" ? "subCategory" : "category",
        categoryId: rowData?.categoryData?._id,
        isAgeUp21: rowData?.isAgeUp21,
        isReturnable: rowData?.isReturnable
      });
      // }
    } else {
      reset({
        name: "",
        type: "",
        categoryId: "",
        taxId: [],
        isAgeUp21: false,
        isReturnable: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  const specificationOptions =
    specifications &&
    specifications.map((s) => {
      const temp = {
        value: s._id,
        label: s.name
      };
      return temp;
    });

  const onSubmit = async (data) => {
    const payloadData = data;
    if (Object.keys(errors).length === 0) {
      if (rowData?._id) {
        // let newTaxId = data.taxId.filter((x) => !x.isFixed);
        let formDataInfo = new FormData();
        formDataInfo.append("name", data.name);
        // formDataInfo.append("taxId", JSON.stringify(newTaxId.map((x) => x.value)));
        if (data?.categoryId) {
          formDataInfo.append("categoryId", data?.categoryId);
        }
        let newSpecificationId = data.specificationId.filter((x) => !x.isFixed);
        formDataInfo.append(
          "specificationId",
          JSON.stringify(
            newSpecificationId && newSpecificationId.length > 0 ? newSpecificationId.map((x) => x.value) : []
          )
        );
        formDataInfo.append("isAgeUp21", data?.isAgeUp21);
        formDataInfo.append("isReturnable", data?.isReturnable);
        if (data.categoryImg && data.categoryImg?.length > 0)
          data.categoryImg.map((x) => {
            return formDataInfo.append("categoryImg[]", x);
          });

        await dispatch(updateCategory(formDataInfo, rowData?._id)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              toast.success(data.payload.message);
              dispatch(fetchCategories(reqData));
              // if (isCategoryFromProduct) {
              //   dispatch(getCategoryTree({ flag: false }));
              // } else {
              dispatch(getCategoryTree({ flag: true }));
              // }

              reset({ name: "", categoryId: "", taxId: [] });
              handleClose();
              setCategoryValue("");
            } else {
              toast.error(data);
              reset({ name: "", categoryId: "", taxId: [] });
            }
          }
        });
      } else {
        // let newTaxId = data.taxId.filter((x) => !x.isFixed);
        // formDataInfo.append("status", data.status);
        // formDataInfo.append("taxId", JSON.stringify(newTaxId.map((x) => x.value)));
        let formDataInfo = new FormData();
        formDataInfo.append("name", data.name);
        data.categoryId !== "" && formDataInfo.append("categoryId", data?.categoryId);
        let newSpecificationId = data.specificationId.filter((x) => !x.isFixed);
        formDataInfo.append(
          "specificationId",
          JSON.stringify(
            newSpecificationId && newSpecificationId.length > 0 ? newSpecificationId.map((x) => x.value) : []
          )
        );
        formDataInfo.append("isAgeUp21", data?.isAgeUp21);
        formDataInfo.append("isReturnable", data?.isReturnable);
        formDataInfo.append("type", data.categoryId !== "" ? "subCategory" : "category");
        if (data.categoryId === "" && data.categoryImg?.length > 0)
          data.categoryImg.map((x) => {
            return formDataInfo.append("categoryImg[]", x);
          });
        await dispatch(addCategory(formDataInfo)).then(async (data) => {
          if (data && data.payload && data.payload.status) {
            toast.success(data.payload.message);
            await dispatch(fetchCategories(reqData));
            if (continues && !GetDepth(categoriesTreeListTrue, watch().categoryId)) {
              await dispatch(getCategoryTree({ flag: true })).then(async (categoryData) => {
                if (categoryData?.type === "CATEGORY_TREE_SUCCESS") {
                  await getPCategories(data.payload.data._id, categoryData.payload.data);
                }
                const { isAgeUp21 = false, isReturnable = false } = payloadData;
                await reset({
                  name: "",
                  categoryId: data.payload.data._id,
                  taxId: [],
                  isAgeUp21: isAgeUp21,
                  isReturnable: isReturnable
                });
                await setContinues(false);
              });
            } else {
              setCategoryValue("");
              handleClose();
              reset({ name: "", categoryId: "", taxId: [] }, { keepValues: true });
              dispatch(getCategoryTree({ flag: false }));
            }
          } else {
            toast.error(data.error);
          }
        });
      }
    }
  };

  React.useEffect(() => {
    if (dropDownTaxes.length > 0) setValue("taxId", Taxs(parrentTax, rowData?.taxData, dropDownTaxes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parrentTax, rowData?.taxData, dropDownTaxes]);

  React.useEffect(() => {
    if (specifications && specifications?.length > 0 && watch()?.categoryId?.length > 0) {
      setValue("specificationId", SpecificationGRP(parrentSpecification, rowData?.specificationId, specifications));
    } else {
      setValue("specificationId", SpecificationGRP([], rowData?.specificationId, specifications));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parrentSpecification, rowData?.specificationId, specifications, watch().categoryId]);

  const findSelectedCategory = (selected = {}, list = []) => {
    for (let index = 0; index < list.length; index++) {
      const curent = list[index];
      if (curent._id === selected.id) {
        return curent;
      } else {
        for (const iterator of curent.subcategories) {
          if (iterator._id === selected.id) {
            return iterator;
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="sm"
        open={open}
        handleClose={() => {
          handleClose();
          setCategoryValue("");
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">{rowData?._id ? "Edit Category" : "Add Category"}</DialogTitle>
          <DialogContent>
            <RequiredFieldLabel />
            <Box>
              <Grid container spacing={gridSpacing}>
                {!rowData?._id ? (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <label className="react-select-labels z-index-6">Category</label>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TreeViewSelect
                            isCategoryFromProduct={false}
                            isClear={true}
                            items={
                              rowData?._id && watch().categoryId !== ""
                                ? ExitingValueFilter(categoriesTreeListTrue, watch().categoryId || rowData?._id)
                                : categoriesTreeListTrue
                            }
                            defaultValue={watch().categoryId}
                            value={value ? value.value : value}
                            onChange={(e, triggeredAction) => {
                              if (triggeredAction.action === "clear") {
                                onChange("");
                                setCategoryValue("");
                                // setDropDownOption(
                                //   dropDownTaxes?.map((x) => ({
                                //     value: x._id,
                                //     label: `${x.label}(${x.taxPercent}%)`
                                //   }))
                                // );
                              } else {
                                setValue("isAgeUp21", findSelectedCategory(e.value, categoriesTreeListTrue).isAgeUp21);
                                setValue(
                                  "isReturnable",
                                  findSelectedCategory(e.value, categoriesTreeListTrue).isReturnable
                                );
                                getPCategories(e.value.id);
                                onChange(e.value.id);
                              }
                            }}
                            placeholder="Select Parent Category"
                          />
                        )}
                        control={control}
                        name="categoryId"
                      />
                      {categoryValue !== "" && (
                        <FormHelperText
                          sx={{
                            fontSize: 14,
                            marginLeft: 0,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "3px",
                            flexWrap: "wrap"
                          }}
                        >
                          <b>Parent</b>:{" "}
                          {categoryValue?.map((x, i) => {
                            return (
                              <span
                                key={i}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "row"
                                }}
                              >
                                {x.name}
                                {/* {x.taxId.length > 0 && (
                                  <Tooltip
                                    title={
                                      <>
                                        Tax:{" "}
                                        {x.taxId?.map((y, index) => {
                                          return (
                                            <span key={index}>
                                              {y.label}({y.taxPercent}%)
                                              {x.taxId?.length - 1 === index ? null : <> {`, `} </>}
                                            </span>
                                          );
                                        })}
                                      </>
                                    }
                                  >
                                    <IconButton sx={{ p: 0, mx: 0.5 }}>
                                      <ErrorOutlineIcon sx={{ fontSize: "15px", color: "#f44336" }} size="small" />
                                    </IconButton>
                                  </Tooltip>
                                )} */}
                                {categoryValue?.length - 1 === i ? null : <ArrowRightAltIcon />}
                              </span>
                            );
                          })}
                        </FormHelperText>
                      )}
                      {errors.categoryId && errors.categoryId.type === "required" && (
                        <ErrorMSG text="Category is required" />
                      )}
                    </FormControl>
                  </Grid>
                ) : categoryValue !== "" ? (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormHelperText
                      sx={{
                        fontSize: 14,
                        marginLeft: 0,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "3px",
                        flexWrap: "wrap"
                      }}
                    >
                      <b>Parent</b>:{" "}
                      {categoryValue?.map((x, i) => {
                        return (
                          <span
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              flexDirection: "row"
                            }}
                          >
                            {x.name}
                            {/* {x.taxId.length > 0 && (
                              <Tooltip
                                title={
                                  <>
                                    Tax:{" "}
                                    {x.taxId?.map((y, index) => {
                                      return (
                                        <span key={index}>
                                          {y.label}({y.taxPercent}%)
                                          {x.taxId?.length - 1 === index ? null : <> {`, `} </>}
                                        </span>
                                      );
                                    })}
                                  </>
                                }
                              >
                                <IconButton sx={{ p: 0, mx: 0.5 }}>
                                  <ErrorOutlineIcon sx={{ fontSize: "15px", color: "#f44336" }} size="small" />
                                </IconButton>
                              </Tooltip>
                            )} */}
                            {categoryValue?.length - 1 === i ? null : <ArrowRightAltIcon />}
                          </span>
                        );
                      })}
                    </FormHelperText>
                  </Grid>
                ) : null}

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Category Name <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("name", { required: true, maxLength: 50 })}
                    />
                    {errors.name && errors.name.type === "maxLength" && (
                      <ErrorMSG text="Category name should not be greater than length 50" />
                    )}
                    {errors.name && errors.name.type === "required" && <ErrorMSG text="Category Name is required" />}
                  </FormControl>
                </Grid>
                {watch().categoryId ? null : (
                  <Grid item md={12} xs={12} sx={{ mx: "", mb: 1 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="4px">
                      <FormControl sx={{ width: "100%" }}>
                        <label className="fileUploaderLabel z-index-2">Category Image</label>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <>
                              <TextField
                                type="file"
                                size="small"
                                className={` z-index-1 customFileInput customFileInputButton customFileInputButton-${
                                  navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                                }`}
                                inputProps={{
                                  multiple: false,
                                  accept: "image/png, image/gif, image/jpg, image/jpeg"
                                }}
                                onChange={async (e) => {
                                  let newArray = [];
                                  if (isMaxLimitCross(e.target.files[0])) {
                                    e.target.value = null;
                                    return toast.error("Maximum image size is 2MB");
                                  }
                                  for await (const [, value] of Object.entries(e.target.files)) {
                                    const idxDot = value.name.lastIndexOf(".") + 1;
                                    const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();

                                    if (validImages.includes(extFile)) {
                                      newArray = [...newArray, value];
                                    } else {
                                      return toast.error("Only jpg/jpeg, gif and png files are allowed!");
                                    }
                                  }
                                  await onChange(newArray);
                                  setFiles(newArray);
                                }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </>
                          )}
                          control={control}
                          // rules={{
                          //   required: !rowData?._id ? true : false
                          // }}
                          rules={{
                            required: false
                          }}
                          name="categoryImg"
                        />

                        {/* {!rowData?._id && errors.productImages && errors.productImages.type === "required" && (
                      <ErrorMSG text="File is required" />
                    )} */}
                      </FormControl>
                      <Box className="vendorModalImageThumb" sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                        {rowData?._id && (
                          <ImageClose files={files} setFiles={setFiles} rowData={rowData} type="product" />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                )}

                {/* {!rowData?._id ? (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          >
                            <FormControlLabel value="true" control={<Radio />} label="Active" />
                            <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                          </RadioGroup>
                        )}
                        control={control}
                        name="status"
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )} */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pt="0px">
                  <Box sx={{ bgcolor: colors.themeGray[100], p: 2, borderRadius: 1 }}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h4">Product Details:</Typography>
                      </Grid>

                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="isAgeUp21"
                            defaultValue={watch()?.isAgeUp21}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                disabled
                                label="Age Verification (21+)"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Checkbox
                                        size="small"
                                        checked={value}
                                        // disabled={rowData?._id ? (watch().categoryId ? false : true) : false}
                                        onChange={(e) => {
                                          onChange(e.target.checked);
                                          // if (rowData?._id) {
                                          //   dispatch(
                                          //     updateReturnCategoryStatus({
                                          //       key: "isAgeUp21",
                                          //       _id: watch().categoryId ? watch().categoryId : rowData?._id,
                                          //       value: e.target.checked
                                          //     })
                                          //   ).then(async (data) => {
                                          //     if (data && data?.payload?.status) {
                                          //       toast.success(data.payload.message);
                                          //       dispatch(fetchCategories(reqData));
                                          //       dispatch(getCategoryTree({ flag: true }));
                                          //     } else {
                                          //       toast.error(data.error);
                                          //     }
                                          //   });
                                          // }
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip
                                        placement="top"
                                        title="Checking this check box implies age confirmation (21+) for all products in this category."
                                        arrow
                                      >
                                        <IconButton className="cursor-help">
                                          <HelpOutlineIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name={`isReturnable`}
                            defaultValue={watch()?.isReturnable}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                size="small"
                                disabled
                                label="Returnable"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Checkbox
                                        size="small"
                                        // disabled={rowData?._id ? (watch().categoryId ? false : true) : false}
                                        checked={value}
                                        onChange={(e) => {
                                          onChange(e.target.checked);
                                          // if (rowData?._id) {
                                          //   dispatch(
                                          //     updateReturnCategoryStatus({
                                          //       key: "isReturnable",
                                          //       _id: watch().categoryId ? watch().categoryId : rowData?._id,
                                          //       value: e.target.checked
                                          //     })
                                          //   ).then(async (data) => {
                                          //     if (data && data?.payload?.status) {
                                          //       toast.success(data.payload.message);
                                          //       dispatch(fetchCategories(reqData));
                                          //       dispatch(getCategoryTree({ flag: true }));
                                          //     } else {
                                          //       toast.error(data.error);
                                          //     }
                                          //   });
                                          // }
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip
                                        placement="top"
                                        title="Checking this box means every product in this category is returnable."
                                        arrow
                                      >
                                        <IconButton className="cursor-help">
                                          <HelpOutlineIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sx={{ paddingTop: "0!important" }} item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormHelperText>The applied changes will be applicable to all its subcategories</FormHelperText>
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl fullWidth>
                          <label className="react-select-labels-1 z-index-2">Select Specification Option</label>
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <Select
                                styles={{
                                  ...styles,
                                  multiValueRemove: (base, state) => {
                                    return state.data.isFixed ? { ...base, display: "none" } : base;
                                  }
                                }}
                                defaultValue={watch() && watch().specificationId}
                                value={value}
                                theme={(x) => ({ ...x, borderRadius: 4 })}
                                className="react-select-no-outline reactSelectFocus z-index-1"
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "Select Specification Option"}
                                isMulti={true}
                                isClearable={value?.some((v) => !v.isFixed)}
                                closeMenuOnSelect={false}
                                placeholder="Select Option"
                                onChange={(e, option) => {
                                  if (option.removedValue && option.removedValue.isFixed) return;
                                  if (option.action === "clear" && watch()?.categoryId?.length > 0) {
                                    onChange(SpecificationGRP(parrentSpecification, [], specifications));
                                  } else {
                                    onChange(e);
                                  }
                                }}
                                options={specificationOptions}
                                isSearchable={true}
                              />
                            )}
                            control={control}
                            rules={{ required: false }}
                            name="specificationId"
                          />
                          {/* {errors.taxId && errors.taxId.type === "required" && <ErrorMSG text="Tax is required" />} */}
                        </FormControl>
                        <FormHelperText>
                          Any specifications you add will be displayed to the product during its creation.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap="8px" flexWrap="wrap">
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                    setCategoryValue("");
                  }}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => setContinues(false)}
                  sx={[
                    {
                      "&:hover": {
                        bgcolor: colors.themeSecondaryBlueColor[100],
                        color: colors.white[100]
                      }
                    },
                    { bgcolor: colors.themeDarkGrey[100], ml: 1 }
                  ]}
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
              </Box>
              {rowData?._id ? null : (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => setContinues(true)}
                  sx={[
                    {
                      "&:hover": {
                        bgcolor: colors.themeSecondaryBlueColor[100],
                        color: colors.white[100]
                      }
                    },
                    { bgcolor: colors.themeDarkGrey[100] }
                  ]}
                >
                  {isLoading ? "Saving..." : "Save & Add Sub Category"}
                </Button>
              )}
            </Box>
          </DialogActions>
        </form>
      </MyDialog>
    </React.Fragment>
  );
}
