import { OrderMap } from "./type";

// category intial state
const initialProducts = {
  orders: [],
  isLoading: false,
  error: null,
  success: false,
  total: null,
  customers: [],
  isSearchLoading: false,
  singleOrder: null,
  isLoadingShip: false,
  orderLocations: [],
  reason: [],
  statusCode: null,
  returnReplaceData: null,
  isOrderLoading: false,
  isReturnReplaceLoader: false
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case OrderMap.ORDER_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orders: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case OrderMap.ORDER_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.CUSTOMER_DROPDOWN_REQUEST:
      return { ...state, isLoading: true };
    case OrderMap.CUSTOMER_DROPDOWN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customers: action.payload.data
      };
    }
    case OrderMap.CUSTOMER_DROPDOWN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OrderMap.SEARCH_INVENTORY_REQUEST:
      return { ...state, isSearchLoading: true };
    case OrderMap.SEARCH_INVENTORY_SUCCESS: {
      return {
        ...state,
        isSearchLoading: false
      };
    }
    case OrderMap.SEARCH_INVENTORY_FAIL:
      return { ...state, isSearchLoading: false, error: action.error };
    case OrderMap.SINGLE_ORDER_REQUEST:
      return { ...state, isLoading: true, statusCode: null };
    case OrderMap.SINGLE_ORDER_SUCCESS:
      return { ...state, isLoading: false, singleOrder: action.payload.data, statusCode: null };
    case OrderMap.SINGLE_ORDER_FAIL:
      return { ...state, isLoading: false, error: action.error, statusCode: action.statusCode };
    case OrderMap.GET_CANCEL_REASON_REQUEST:
      return { ...state, isLoadingShip: true };
    case OrderMap.GET_CANCEL_REASON_SUCCESS:
      return { ...state, reason: action.payload.data, isLoadingShip: false };
    case OrderMap.GET_CANCEL_REASON_FAIL:
      return { ...state, isLoadingShip: false };
    case OrderMap.RETURNREPLACE_REQUEST:
      return { ...state, isReturnReplaceLoader: true };
    case OrderMap.RETURNREPLACE_SUCCESS:
      return { ...state, returnReplaceData: action.payload.data, isReturnReplaceLoader: false };
    case OrderMap.RETURNREPLACE_FAIL:
      return { ...state, returnReplaceData: null, isReturnReplaceLoader: false };
    default:
      return state;
  }
};
