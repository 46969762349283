import * as React from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

const marks = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 50,
    label: "50"
  },
  {
    value: 100,
    label: "100"
  },
  {
    value: 150,
    label: "150"
  },
  {
    value: 200,
    label: "200"
  },
  {
    value: 250,
    label: "250"
  },
  {
    value: 300,
    label: "300"
  }
];

export default function VerticalSlider({ value, onChange }) {
  function valueText(value) {
    return `${value}°C`;
  }
  return (
    <Stack spacing={1} direction="column" className="sliderPad">
      <Typography id="non-linear-slider">
        <span> Stock Purchase Limit:</span>
        {/* <span>{`${value[0]} to ${value[1]}`}</span> */}
      </Typography>
      <Slider
        getAriaLabel={() => "Temperature"}
        orientation="horizontal"
        getAriaValueText={valueText}
        defaultValue={[1, 150]}
        valueLabelDisplay="auto"
        marks={marks}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        max={300}
        min={1}
      />
    </Stack>
  );
}
