export const settingsType = {
  // get payment
  GET_PAYMENT_TYPE_REQUEST: " GET_PAYMENT_TYPE_REQUEST",
  GET_PAYMENT_TYPE_SUCCESS: " GET_PAYMENT_TYPE_SUCCESS",
  GET_PAYMENT_TYPE_FAIL: " GET_PAYMENT_TYPE_FAIL",

  // change payment
  CHANGE_PAYMENT_TYPE_REQUEST: "CHANGE_PAYMENT_TYPE_REQUEST",
  CHANGE_PAYMENT_TYPE_SUCCESS: "CHANGE_PAYMENT_TYPE_SUCCESS",
  CHANGE_PAYMENT_TYPE_FAIL: "CHANGE_PAYMENT_TYPE_FAIL"
};
