import { TablePagination } from "@mui/material";
import React from "react";

const Pagination = ({ dataFilter, total, onBatchChange, setDataFilter }) => {
  return (
    <TablePagination
      className="tablePagination"
      component="div"
      count={total !== undefined ? total : 0}
      page={dataFilter.page - 1}
      onPageChange={onBatchChange}
      rowsPerPage={dataFilter.limit}
      onRowsPerPageChange={(e) => setDataFilter({ ...dataFilter, limit: parseInt(e.target.value), page: 1 })}
    />
  );
};

export default Pagination;
