import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { addStoreProduct, getStoreProductsData } from "./redux/action";
import { Button, useTheme, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import MyCard from "../../components/MyCard";
import { tokens } from "../../theme";
import { Header, InternalPageHeader } from "../../components/Header";
import DetailsFilter from "./DetailsFilter";
import MyCheckboxTree from "../../components/MyCheckboxTree";
import GetUserRole from "../../utils/GetUserRole";
import { useForm } from "react-hook-form";

const result = (inputObject) => {
  return Object.entries(inputObject)
    .reduce((acc, [key, value]) => {
      if (value === undefined && value === 0) return acc;
      const match = key.match(/(oldPrice|price)([a-f0-9]{24})/);
      if (!match) return acc;

      const [, type, id] = match;
      let item = acc.find((obj) => obj.id === id);
      if (!item) {
        item = { id, price: null, oldPrice: null };
        acc.push(item);
      }
      item[type] = typeof value === "string" ? parseFloat(value) : value;
      return acc;
    }, [])
    .filter((item) => (item.price !== null || item.oldPrice !== null) && (item.oldPrice !== 0 || item.price !== 0));
};

export default function StoreRoles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [checked, setChecked] = useState([""]);
  const [nodes, setNodes] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [subProductQty, setSubProductQty] = useState("");
  const [reqData, setReqData] = useState({
    categoryId: "",
    search: ""
  });

  const assign_Products = GetUserRole("assign_subProduct");
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { isLoading } = useSelector((state) => state.store, shallowEqual);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
    // defaultValues: defaultStates
  });

  // useEffect(() => {
  //   if (user?.userType === "store" && user?._id) {
  //     document.title = "Assign Products | Doorvenza";
  //     dispatch(getSingleStore(user?._id)).then(async (data) => {
  //       if (data && data?.payload?.status) {
  //         setChecked(data?.payload?.data?.storeData?.subProductIds || []);
  //         setStoreName(data?.payload?.data?.storeData?.storeName);
  //       }
  //     });
  //   } else {
  //     if (id) {
  //       dispatch(getSingleStore(id)).then(async (data) => {
  //         if (data && data?.payload?.status) {
  //           setChecked(data?.payload?.data?.storeData?.subProductIds || []);
  //           setStoreName(data?.payload?.data?.storeData?.storeName);
  //         }
  //       });
  //     }
  //   }
  // }, [dispatch]);

  const findCheckedSubProduct = (arr) => {
    return arr
      .flatMap((item) => item.subProduct)
      .filter((subProduct) => subProduct.selected)
      .map((subProduct) => subProduct._id);
  };

  const getInitialCall = () => {
    dispatch(getStoreProductsData({ ...reqData, locationId: id })).then((data) => {
      if (data?.type === "GET_STORE_PRODCUTS_SUCCESS") {
        setNodes(data?.payload?.data.tree);
        setStoreName(data?.payload?.data?.locationName);
        setSubProductQty(data?.payload?.data?.tree?.reduce((acc, curr) => acc + curr.subProduct.length, 0));
        setChecked(findCheckedSubProduct(data?.payload?.data?.tree));
      }
      const res = data?.payload?.data?.tree?.reduce((acc, item) => {
        item.subProduct.forEach((subProduct) => {
          acc[`oldPrice${subProduct._id}`] = subProduct.oldPrice;
          acc[`price${subProduct._id}`] = subProduct.price;
        });
        return acc;
      }, {});

      reset(res);
    });
  };

  useEffect(() => {
    getInitialCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reqData]);

  const findCheckedValues = (arr = [], checkedArray) => {
    return arr.filter((val) => checkedArray.includes(val.id));
  };
  const assignRole = (data) => {
    const payload = {
      subProduct: findCheckedValues(result(data), checked),
      _id: user?.userType === "store" ? user?._id : id
    };
    dispatch(addStoreProduct(payload)).then((data) => {
      if (data?.type === "ADD_STORE_PRODCUTS_SUCCESS") {
        toast.success(data.payload.message);
        getInitialCall();
        // if (user?.userType === "store") {
        //   navigate("/dashboard");
        // } else {
        //   navigate("/locations");
        // }
      }
    });
  };

  const onSubmit = (data) => {
    assignRole(data);
  };
  return (
    <Box mx="20px">
      <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          {user?.userType === "store" ? (
            <Header title={"Assign Products"} />
          ) : (
            <InternalPageHeader
              onClick={() => {
                navigate(-1);
              }}
              title="Assign Products"
            />
          )}
        </Grid>
        {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ textAlign: "right" }}>
          {isLoading ? null : (
            <Button
              disabled={assign_Products ? false : true}
              variant="contained"
              onClick={assignRole}
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              ADD PRODUCTS
            </Button>
          )}
        </Grid> */}
      </Grid>
      <MyCard className={isLoading ? "loading-top" : ""} sx={{ overflow: "unset" }}>
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h3" fontWeight="bold">
              Location Name:{" "}
              <Typography variant="span" color={colors.themePrimary[900]}>
                {storeName}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right" }}>
            <Typography variant="h4" fontWeight="bold">
              Selected/{`${reqData.search !== "" ? "Searched" : "Available"}`} Products:{" "}
              <Typography variant="span" color={colors.secondary[900]}>
                {checked.length}/{subProductQty}
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <DetailsFilter setReqData={setReqData} reqData={reqData} />

        {nodes?.length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box className="productTree" sx={{ overflowY: "auto", maxHeight: 500, minHeight: 300 }}>
              <MyCheckboxTree
                nodes={nodes}
                checked={checked}
                setChecked={setChecked}
                disabled={assign_Products}
                watch={watch}
                control={control}
                errors={errors}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap="10px"
              width="100%"
              bgcolor="#fff"
              position="sticky"
              bottom="0"
              mt={3}
            >
              {user?.userType !== "store" && (
                <Box>
                  <NavLink to="/locations">
                    <Button variant="outlined">Cancel</Button>
                  </NavLink>
                </Box>
              )}
              <Box>
                {isLoading ? null : (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={assign_Products ? false : true}
                    // onClick={assignRole}
                    sx={[
                      {
                        "&:hover": {
                          bgcolor: colors.themeSecondaryBlueColor[100],
                          color: colors.white[100]
                        }
                      },
                      { bgcolor: colors.themeDarkGrey[100] }
                    ]}
                  >
                    ADD PRODUCTS
                  </Button>
                )}
              </Box>
            </Box>
            {/* <Button type="submit" variant="contained">
                Save
              </Button> */}
          </form>
        ) : (
          <h2>No Data Found</h2>
        )}
      </MyCard>
    </Box>
  );
}
