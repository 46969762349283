import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField
} from "@mui/material";
import { useState } from "react";
import noImage from "../../images/no-image.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import { FloatingNumber } from "../../utils/regex";
// import ErrorMSG from "../ErrorMSG";
import { inputScroll } from "../../utils/custom";
import { Controller } from "react-hook-form";
import HoverTooltip from "../HoverTooltip";

export default function MyCheckboxTree({ nodes, checked, setChecked, disabled, watch, control, errors }) {
  return (
    <List sx={{ width: "100%" }}>
      {nodes?.map((item, i) => {
        return (
          <RowData
            item={item}
            key={item?._id}
            checked={checked}
            setChecked={setChecked}
            watch={watch}
            disabled={disabled}
            errors={errors}
            control={control}
          />
        );
      })}
    </List>
  );
}

function RowData({ item, checked, setChecked, disabled, errors, control, watch }) {
  // console.log(item);
  const [isExpanded, setIsExpanded] = useState(false);
  const baseUrl = process.env.REACT_APP_BACKEND_URI;
  const onImageError = (e) => {
    e.target.src = noImage;
  };
  return (
    <ListItem>
      <Box className={`${isExpanded ? "treeOddBg" : ""}`}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ visibility: `${item?.subProduct && item.subProduct?.length > 0 ? "visible" : "hidden"}` }}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 0.5 }}
                disabled={!disabled || item.subProduct?.length <= 0}
                checked={
                  checked.filter((x) => x === item._id).length > 0 ||
                  (item?.subProduct &&
                    item.subProduct?.length > 0 &&
                    item?.subProduct.filter((y) => checked.filter((x) => x === y._id).length > 0 && y).length > 0)
                }
                onChange={() => {
                  let array = item.subProduct.map((x) => x._id);

                  if (item?.subProduct && item.subProduct?.length > 0) {
                    if (item?.subProduct.filter((y) => checked.some((x) => x === y._id)).length > 0) {
                      let newArray = checked.filter((y) => !array.some((z) => y === z));
                      setChecked([...newArray]);
                    } else {
                      setChecked([...checked, ...array]);
                    }
                  } else {
                    if (checked.filter((x) => x === item._id).length > 0) {
                      let newArray = checked.filter((y) => item._id !== y);
                      setChecked([...newArray]);
                    } else {
                      setChecked([...checked, item._id]);
                    }
                  }
                }}
              />
            }
            label={
              <Box className="treeValue">
                <img
                  alt={item?.name}
                  style={{ width: 50, objectFit: "contain", pointerEvents: "none", userSelect: "none" }}
                  src={
                    item?.productImages[0] && item?.productImages[0]?.fileUrl
                      ? baseUrl + "/product/thumb-" + item?.productImages[0]?.fileName
                      : noImage
                  }
                  onError={onImageError}
                />
                <span style={{ userSelect: "none" }}>{item.name}</span>
              </Box>
            }
          />
        </Box>
        {/* {isExpanded && item?.subProduct && item.subProduct?.length > 0 && ( */}
        <List
          className={`treeSubRow ${
            isExpanded || Object.keys(errors).length !== 0
              ? // (Object.keys(errors).length !== 0 &&
                // (Object.keys(errors).includes(`price${mainId}${index}`) ||
                //   Object.keys(errors).includes(`oldPrice${mainId}${index}`)))
                "show"
              : "customInputFileField"
          }`}
        >
          {item?.subProduct &&
            item.subProduct?.length > 0 &&
            item?.subProduct.map((subItem, index) => {
              return (
                <ListItem key={subItem?._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!disabled}
                        sx={{ mr: 0.5 }}
                        checked={checked.filter((x) => x === subItem._id).length > 0}
                        onChange={() => {
                          if (checked.filter((x) => x === subItem._id).length > 0) {
                            let newArray = checked.filter((y) => subItem._id !== y);
                            setChecked([...newArray]);
                          } else {
                            setChecked([...checked, subItem._id]);
                          }
                        }}
                      />
                    }
                    label={
                      <Box className="treeValue">
                        <img
                          alt={item?.name}
                          style={{
                            width: 63,
                            borderRadius: "5px",
                            objectFit: "contain",
                            pointerEvents: "none",
                            userSelect: "none"
                          }}
                          src={
                            subItem?.subProductImages && subItem?.subProductImages?.length
                              ? baseUrl + "/subProduct/thumb-" + subItem?.subProductImages[0]?.fileName
                              : noImage
                          }
                          onError={onImageError}
                        />
                        <Box>
                          <p style={{ userSelect: "none", margin: "0", marginBottom: "8px" }}>{subItem.name}</p>
                          <Box sx={{ display: "flex", gap: "15px" }}>
                            <Box>
                              <Controller
                                render={({ field: { onChange, value } }) => {
                                  const isError =
                                    Object.keys(errors)?.length > 0 &&
                                    Object.keys(errors).includes(`price${subItem._id}`);
                                  return (
                                    <FormControl fullWidth>
                                      <TextField
                                        className="tree-price-input"
                                        sx={{ width: "150px" }}
                                        fullWidth
                                        type="number"
                                        size="small"
                                        error={isError}
                                        disabled={!checked.filter((x) => x === subItem._id).length > 0}
                                        onWheel={inputScroll}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                          step: 0.01
                                        }}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {isError && (
                                                <HoverTooltip
                                                  style={{ color: "#f00" }}
                                                  title="Price is required and must be more than zero"
                                                />
                                              )}
                                            </InputAdornment>
                                          )
                                        }}
                                        label={
                                          <p className="my-0">
                                            Price <span className="danger mr-0">*</span>
                                          </p>
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (value.length <= 8) {
                                            onChange(value);
                                          }
                                        }}
                                        value={value}
                                      />
                                    </FormControl>
                                  );
                                }}
                                control={control}
                                rules={{
                                  required: checked?.includes(subItem?._id) ? true : false,
                                  pattern: FloatingNumber,
                                  validate: (value) => !!(value > 0 && checked?.includes(subItem?._id)) || true
                                }}
                                name={`price${subItem._id}`}
                              />
                            </Box>
                            <Box>
                              <Controller
                                render={({ field: { onChange, value } }) => {
                                  const isError =
                                    Object.keys(errors)?.length > 0 &&
                                    Object.keys(errors).includes(`oldPrice${subItem._id}`);
                                  return (
                                    <>
                                      <TextField
                                        fullWidth
                                        className="tree-price-input"
                                        variant="outlined"
                                        sx={{ width: "150px" }}
                                        type="text"
                                        size="small"
                                        error={isError}
                                        disabled={!checked.filter((x) => x === subItem._id).length > 0}
                                        onWheel={inputScroll}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ step: 0.01 }}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {isError && (
                                                <HoverTooltip
                                                  style={{ color: "#f00" }}
                                                  title="The price is required, must be more than zero, and greater than the sales price"
                                                />
                                              )}
                                            </InputAdornment>
                                          )
                                        }}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (value.length <= 8) {
                                            onChange(value);
                                          }
                                        }}
                                        value={value}
                                        label={
                                          <p className="my-0">
                                            Compared Price <span className="danger mr-0">*</span>
                                          </p>
                                        }
                                      />
                                    </>
                                  );
                                }}
                                control={control}
                                rules={{
                                  required: checked?.includes(subItem?._id) ? true : false,
                                  pattern: FloatingNumber,
                                  validate: (val) => {
                                    const v = `price${subItem._id}`;
                                    return watch()[v] > 0 ? Number(watch()[v]) < Number(val) : true;
                                  }
                                }}
                                name={`oldPrice${subItem._id}`}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
        {/* )} */}
      </Box>
    </ListItem>
  );
}
