import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import React from "react";
import ErrorMSG from "../../components/ErrorMSG";
import Select from "react-select";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import CustomIconButton from "../../components/CustomIconButton";
import LableText from "../../components/LableText";
import TreeViewSelect from "../category/TreeViewSelect";
import AddIcon from "@mui/icons-material/Add";
import { styles } from "../../utils/custom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Delete } from "@mui/icons-material";
import noImage from "../../images/no-image.png";
import { API_ROOT } from "../../utils/config";
import { toast } from "react-toastify";
import {
  addProductImage,
  deleteFromAPiProduct,
  getSingleProduct,
  getSpecificationsByCategory
} from "../product/redux/action";
import { useDispatch } from "react-redux";
import { isMaxLimitCross, validImages } from "../../utils/common";
import GetUserRole from "../../utils/GetUserRole";

export default function FormFields({
  singleUpdate,
  control,
  errors,
  reset,
  watch,
  setOpen,
  setValue,
  sliderFile,
  setSliderFiles,
  open,
  openBrand,
  setBrandOpen,
  brandsOptions,
  categoriesTreeList,
  setCategoryValue,
  categoryValue,
  setDropDownOption,
  dropDownTaxes,
  getPCategories,
  setFiles,
  files,
  addedBrand = ""
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const brandAccess = GetUserRole("brand_add");
  const CategoryAccess = GetUserRole("category_add");
  // const [imageUpdate, setImageUpdate] = React.useState(true);
  const [imageLoader, setImageLoader] = React.useState(false);
  const [category, setCategory] = React.useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();

  const onImageError = (e) => {
    e.target.src = noImage;
  };
  // const selectedOption = () => {
  //   if (!brandsOptions) return;
  //   let brand = brandsOptions.find((element) => element.value === addedBrand);
  //   if (!brand) {
  //     return (brand = { label: "", value: "" });
  //   } else {
  //     return brand;
  //   }
  // };
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className="product-card" sx={{ height: "100%" }}>
          <Box p={2}>
            <Grid container spacing={gridSpacing}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography fontSize="14px" mb={0.5}>
                  Upload Product Images<span className="danger mr-0">*</span>
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <label htmlFor="productImages" className="customFileInputButtonProduct">
                    {imageLoader ? <CircularProgress color="secondary" /> : "Click here to Upload Product Images"}
                  </label>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          type="file"
                          size="small"
                          className="customFileInput z-index-1"
                          id="productImages"
                          inputProps={{
                            multiple: true,
                            accept: "image/gif, image/jpeg, image/png, image/jpg"
                          }}
                          onChange={async (e) => {
                            const newFiles = Array.from(e.target.files);
                            const imgCount = newFiles.length + files.length;
                            const allFile = [];
                            if (imgCount > 5) {
                              return toast.error("Maximum five images are allowed");
                            }

                            const invalidFiles = newFiles.filter((file) => {
                              const ext = file.name.split(".").pop().toLowerCase();
                              return !validImages.includes(ext);
                            });

                            if (invalidFiles.length > 0) {
                              return toast.error("Only jpg/jpeg, gif and png files are allowed!");
                            }

                            if (id) {
                              const formDataInfo = new FormData();
                              formDataInfo.append("_id", id);
                              newFiles.forEach((file) => {
                                if (file?.size <= 2000000) {
                                  allFile.push(file);
                                  formDataInfo.append("productImage[]", file);
                                } else {
                                  toast.error("Maximum image size is 2MB");
                                }
                              });
                              if (allFile.length >= 1) {
                                try {
                                  const { payload } = await dispatch(addProductImage(formDataInfo));
                                  if (payload.status) {
                                    toast.success(payload.message);
                                    const { payload: singleProductPayload } = await dispatch(getSingleProduct(id));
                                    reset({
                                      ...watch(),
                                      productImage: singleProductPayload?.data?.productShow?.productImage
                                    });
                                  } else {
                                    toast.error(payload.error);
                                  }
                                } catch (error) {}
                              }
                            } else {
                              let filesToUpload = [];
                              newFiles.forEach((file) => {
                                if (file?.size <= 2000000) {
                                  filesToUpload.push(file);
                                } else {
                                  toast.error("Maximum image size is 2MB");
                                }
                              });
                              await onChange(filesToUpload);
                              setFiles([...filesToUpload, ...files]);
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </>
                    )}
                    control={control}
                    rules={{ required: files?.length > 0 ? false : true }}
                    name="productImage"
                  />
                  {errors.productImage && errors.productImage.type === "required" && (
                    <ErrorMSG text="Product Images are required" />
                  )}
                </FormControl>
                <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
                  Only *.png, *.jpg and *.jpeg image files are accepted / maximum image size is <strong>2 MB</strong>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
                  You have the capability to include up to five images.
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {!id && files && files.length > 0
                    ? files?.map((e, index) => {
                        return (
                          <Card key={index} className="ticketPhoto" sx={{ position: "relative", padding: "8px" }}>
                            <>
                              <img
                                src={URL.createObjectURL(e)}
                                alt=""
                                onError={onImageError}
                                style={{ objectFit: "contain" }}
                              />
                              {files.length > 1 && (
                                <IconButton
                                  onClick={() => {
                                    files.splice(index, 1);
                                    setFiles([...files]);
                                  }}
                                >
                                  <Delete width={20} height={20} />
                                </IconButton>
                              )}
                            </>
                          </Card>
                        );
                      })
                    : watch()?.productImage.length > 0 &&
                      watch()?.productImage?.map((e, index) => {
                        return (
                          <Card key={index} className="ticketPhoto" sx={{ position: "relative", padding: "8px" }}>
                            <img
                              src={API_ROOT + e?.fileUrl}
                              alt=""
                              onError={onImageError}
                              style={{ objectFit: "contain" }}
                            />
                            {watch()?.productImage.length > 1 && (
                              <IconButton
                                disabled={imageLoader}
                                onClick={async () => {
                                  await setImageLoader(true);
                                  await dispatch(
                                    deleteFromAPiProduct({
                                      type: "product",
                                      _id: id,
                                      imageId: e._id
                                    })
                                  ).then(async (data) => {
                                    if (data && data?.payload?.status) {
                                      toast.success(data.payload?.message);
                                      dispatch(getSingleProduct(id)).then((data) => {
                                        let tempVariable = data.payload?.data?.productShow;
                                        // setImageUpdate(false);
                                        reset({
                                          ...watch(),
                                          productImage: tempVariable && tempVariable?.productImage
                                        });
                                      });
                                    } else {
                                      toast.error(data.error);
                                    }
                                  });
                                  await setImageLoader(false);
                                }}
                              >
                                <Delete width={20} height={20} />
                              </IconButton>
                            )}
                          </Card>
                        );
                      })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xl={12} lg={12} md={6} sm={6} xs={12}>
        <Card className="product-card" sx={{ height: "100%" }}>
          <CardHeader
            action={
              CategoryAccess ? (
                <CustomIconButton
                  onClick={() => {
                    setOpen(!open);
                  }}
                  p="2px"
                >
                  <AddIcon />
                </CustomIconButton>
              ) : null
            }
            title="Add/Select Category"
            sx={{ py: 2, color: `${colors.secondary[900]}` }}
          />
          <CardContent>
            <FormControl fullWidth>
              <label className="react-select-labels-1 z-index-6">
                <LableText>Category</LableText>
              </label>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TreeViewSelect
                    isCategoryFromProduct={true}
                    isClear={false}
                    items={categoriesTreeList}
                    defaultValue={watch().categoryId}
                    value={value ? value.value : value}
                    onChange={(e, triggeredAction) => {
                      if (triggeredAction.action === "clear") {
                        onChange("");
                        setCategoryValue("");
                        setDropDownOption(
                          dropDownTaxes?.map((x) => ({
                            value: x._id,
                            label: `${x.label}(${x.taxPercent}%)`
                          }))
                        );
                      } else {
                        getPCategories(e.value.id);
                        onChange(e.value.id);
                        dispatch(
                          getSpecificationsByCategory({
                            id: e.value.id
                          })
                        );

                        if (id) {
                          setCategory(true);
                          singleUpdate("categoryId", e.value.id);
                        }
                      }
                    }}
                    placeholder="Select Parent Category"
                  />
                )}
                control={control}
                rules={{ required: true }}
                name="categoryId"
              />
              {categoryValue !== "" && (
                <FormHelperText
                  sx={{
                    fontSize: 14,
                    marginLeft: 0,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "3px",
                    flexWrap: "wrap"
                  }}
                >
                  <b>Parent</b>:{" "}
                  {categoryValue?.map((x, i) => {
                    return (
                      <span
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                          wordBreak: "break-all"
                        }}
                      >
                        {x.name}

                        {categoryValue?.length - 1 === i ? null : <ArrowRightAltIcon />}
                      </span>
                    );
                  })}
                </FormHelperText>
              )}
              {errors.categoryId && errors.categoryId.type === "required" && <ErrorMSG text="Category is required" />}
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xl={12} lg={12} md={6} sm={6} xs={12}>
        <Card className="product-card" sx={{ height: "100%" }}>
          <CardHeader
            action={
              brandAccess ? (
                <CustomIconButton
                  onClick={() => {
                    setBrandOpen(!openBrand);
                  }}
                  p="2px"
                >
                  <AddIcon />
                </CustomIconButton>
              ) : null
            }
            title="Select Brand"
            sx={{ py: 2, color: `${colors.secondary[900]}` }}
          />
          <CardContent>
            <FormControl fullWidth>
              <label className="react-select-labels-1 z-index-2">
                <LableText>Select Brand</LableText>
              </label>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Select
                    size="small"
                    styles={styles}
                    value={value}
                    theme={(x) => ({ ...x, borderRadius: 4 })}
                    className="react-select-no-outline reactSelectFocus z-index-1"
                    classNamePrefix="react-select"
                    placeholder="Select Brand"
                    onChange={(e, option) => {
                      if (option.action === "clear") {
                        onChange([]);
                      } else {
                        onChange(e);
                      }
                      if (id) {
                        singleUpdate("brandId", e.value);
                        // setExpanded(false)
                      }
                    }}
                    options={brandsOptions}
                    isSearchable={true}
                  />
                )}
                control={control}
                rules={{ required: true }}
                name="brandId"
              />
              {errors.brandId && errors.brandId.type === "required" && <ErrorMSG text="Brand Name is required" />}
            </FormControl>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={12} lg={12} md={6} sm={6} xs={12}>
        <Card
          className="product-card"
          sx={{
            backgroundColor: `${
              watch().status === "true" || watch().status === true
                ? "rgba(47, 125, 51, 0.1) !important"
                : "rgba(211, 47, 47, 0.1) !important"
            }`,
            height: "100%"
          }}
        >
          <CardHeader
            // action={
            //   <IconButton aria-label="settings">
            //     <Brightness1Icon color="error" sx={{ fontSize: 15 }} />
            //   </IconButton>
            // }
            title="Product Status"
            sx={{ pt: 2, pb: 0, color: `${colors.secondary[900]}` }}
          />
          <Box sx={{ px: 2, pb: 1 }}>
            <FormControl fullWidth>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    sx={{
                      "& .PrivateSwitchBase-input": {
                        zIndex: 0
                      }
                    }}
                    onChange={(e) => {
                      if (id) {
                        singleUpdate("status", e.target.value);
                      }
                      onChange(e.target.value);
                    }}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Active" />
                    <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                  </RadioGroup>
                )}
                control={control}
                name="status"
              />
            </FormControl>
          </Box>
        </Card>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className="product-card" sx={{ height: "100%" }}>
          <Box p={2}>
            <Grid container spacing={gridSpacing}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography fontSize="14px" mb={0.5}>
                  Upload Slider Image
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <label htmlFor="sliderImage" className="customFileInputButtonProduct">
                    {imageLoader ? <CircularProgress color="secondary" /> : "Click here to Upload Slider Image"}
                  </label>
                  <Controller
                    render={({ field: { onChange } }) => (
                      <>
                        <TextField
                          type="file"
                          size="small"
                          className="customFileInput z-index-1"
                          id="sliderImage"
                          inputProps={{
                            multiple: false,
                            accept: "image/gif, image/jpeg, image/png, image/jpg"
                          }}
                          onChange={async (e) => {
                            let newArray = [];
                            for await (const [, value] of Object.entries(e.target.files)) {
                              const idxDot = value.name.lastIndexOf(".") + 1;
                              const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                              if (validImages.includes(extFile)) {
                                newArray = [...newArray, value];
                              } else {
                                return toast.error("Only jpg/jpeg, gif and png files are allowed!");
                              }
                            }
                            if (isMaxLimitCross(e.target.files[0])) {
                              return toast.error("Maximum image size is 2MB");
                            }

                            if (id && newArray.length > 0) {
                              singleUpdate("sliderImage", newArray[0]);
                              await onChange(newArray);
                            } else {
                              if (newArray.length > 0) setSliderFiles([...newArray]);
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </>
                    )}
                    control={control}
                    // rules={{ required: watch()?.sliderImage.length > 0 ? false : true }}
                    // rules={{ required: false }}
                    name="sliderImage"
                  />
                  {errors.sliderImage && errors.sliderImage.type === "required" && (
                    <ErrorMSG text="Slider Image are required" />
                  )}
                </FormControl>
                <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
                  Only *.png, *.jpg and *.jpeg image files are accepted / maximum image size is <strong>2 MB</strong>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
                  Upload images sized 1920px x 600px, Keep content dimensions within 1240px x 600px for mobile display
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {sliderFile && sliderFile.length > 0
                    ? sliderFile?.map((e, index) => {
                        return (
                          <Card key={index} className="ticketPhoto" sx={{ position: "relative", padding: "8px" }}>
                            <>
                              <img
                                src={URL.createObjectURL(e)}
                                alt=""
                                // onError={onImageError}
                                style={{ objectFit: "contain" }}
                              />
                              {sliderFile.length > 0 && (
                                <IconButton
                                  onClick={() => {
                                    sliderFile.splice(index, 1);
                                    setSliderFiles([...sliderFile]);
                                  }}
                                >
                                  <Delete width={20} height={20} />
                                </IconButton>
                              )}
                            </>
                          </Card>
                        );
                      })
                    : watch()?.sliderImage && watch()?.sliderImage.length > 0
                    ? watch()?.sliderImage?.map((e, index) => {
                        return (
                          <Card key={index} className="ticketPhoto" sx={{ position: "relative", padding: "8px" }}>
                            <img
                              src={API_ROOT + e?.fileUrl}
                              alt=""
                              onError={onImageError}
                              style={{ objectFit: "contain" }}
                            />
                            {watch()?.sliderImage && watch()?.sliderImage.length > 0 && (
                              <IconButton
                                disabled={imageLoader}
                                onClick={async () => {
                                  await setImageLoader(true);
                                  await dispatch(
                                    deleteFromAPiProduct({
                                      type: "sliderImage",
                                      _id: id,
                                      imageId: e._id
                                    })
                                  ).then(async (data) => {
                                    if (data && data?.payload?.status) {
                                      toast.success(data.payload?.message);
                                      dispatch(getSingleProduct(id)).then((data) => {
                                        let tempVariable = data.payload?.data?.productShow;
                                        // setImageUpdate(false);
                                        reset({
                                          ...watch(),
                                          sliderImage: tempVariable && tempVariable?.sliderImage
                                        });
                                      });
                                    } else {
                                      toast.error(data.error);
                                    }
                                  });
                                  await setImageLoader(false);
                                }}
                              >
                                <Delete width={20} height={20} />
                              </IconButton>
                            )}
                          </Card>
                        );
                      })
                    : ""}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Dialog
        maxWidth={"sm"}
        disableEscapeKeyDown={true}
        open={category}
        handleClose={() => {
          setCategory(false);
        }}
      >
        <DialogTitle className="modal-header">Category changed. Revise the specification.</DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "center"
          }}
        >
          <Button
            variant="contained"
            sx={[
              {
                "&:hover": {
                  bgcolor: colors.themeSecondaryBlueColor[100],
                  color: colors.white[100]
                }
              },
              { bgcolor: colors.themeDarkGrey[100] }
            ]}
            onClick={() => {
              setCategory(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
