import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField, Grid, FormControl, useTheme, useMediaQuery, FormHelperText } from "@mui/material";
import { fetchDropdownTaxs } from "../tax/redux/action";
import { useForm, Controller } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import MyDialog from "../../components/MyDialog";
import { styles } from "../../utils/custom";
import RequiredFieldLabel from "../../components/RequiredFieldLabel";
import Select from "react-select";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { updateCategoryTax } from "./redux/action";
import { getCategoryTaxTree } from "../category/redux/action";

export default function TaxAdd({ open, handleClose, reqData, rowData, categoryId, wichOne, setWichOne }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const { id } = useParams();
  const [taxToggle, setTaxToggle] = useState("commissionScreen");
  const [isTaxAdded, setIsTaxAdded] = useState(false);

  //   // main state to get the properties
  // const { common } = useSelector(
  //   (state) => ({
  //     common: state && state.taxs && state.taxs ? state.taxs : []
  //   }),
  //   shallowEqual
  // );
  // const { isLoading } = common;
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const { dropDownTaxes } = useSelector(
    (state) => ({
      dropDownTaxes: state && state.categories && state.categories.dropDownTaxes ? state.categories.dropDownTaxes : []
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchDropdownTaxs());
  }, [dispatch]);

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: { label: "", commission: "", taxPercent: "", taxId: "" }
  });

  //tax options
  const taxOptions =
    dropDownTaxes &&
    dropDownTaxes.length > 0 &&
    dropDownTaxes.map((tax) => {
      const options = {
        label: `${tax.label}-${tax.taxPercent}%`,
        value: tax._id
      };
      return options;
    });

  useEffect(() => {
    if (rowData?._id && taxToggle === "commissionScreen") {
      reset({
        commission: rowData?.commission,
        // status: rowData?.status,
        taxId:
          Object.keys(rowData?.taxData).length > 0
            ? {
                label: `${rowData?.taxData?.label}-${rowData?.taxData?.taxPercent}%`,
                value: rowData?.taxData?._id
              }
            : ""
      });
    } else {
      reset({ label: "", commission: "", taxPercent: "", taxId: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData?._id, taxToggle, open]);

  const onSubmit = async (data) => {
    const newReqData = await {
      storeId: id,
      categoryId: categoryId,
      label: data?.label,
      commission: data?.commission,
      taxPercent: data?.taxPercent,
      taxId: data?.taxId?.value
    };
    wichOne === "comission" && delete newReqData.taxId;
    wichOne === "comission" && delete newReqData.taxPercent;
    (wichOne === "tax" || wichOne === "") && delete newReqData.commission;
    if (Object.keys(errors).length === 0) {
      setIsTaxAdded(true);
      await dispatch(updateCategoryTax(newReqData, rowData?._id))
        .then(async (data) => {
          if (data && data?.payload?.status) {
            toast.success(data.payload.message);
            setIsTaxAdded(false);
            reset({ label: "", commission: "", taxPercent: "", taxId: "" });
            setTaxToggle("commissionScreen");
            handleClose();
            dispatch(getCategoryTaxTree({ storeId: id }));
            dispatch(fetchDropdownTaxs());
          }
        })
        .then((error) => setIsTaxAdded(false));
    }
  };

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="sm"
        open={open}
        handleClose={() => {
          handleClose();
          setTaxToggle("commissionScreen");
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">
            {taxToggle === "commissionScreen" ? `Edit ${wichOne === "tax" ? "Tax" : "Commission"}` : "Add Tax"}
          </DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" mb={2}>
              <RequiredFieldLabel mb="0" />
              {user?.userRole?.includes("tax_add") && taxToggle === "commissionScreen" && wichOne === "tax" && (
                <Button
                  onClick={() => {
                    setWichOne("");
                    setTaxToggle("taxScreen");
                  }}
                  sx={{
                    bgcolor: colors.themeGray[200],
                    gap: "4px"
                  }}
                >
                  <AddIcon sx={{ fontSize: "18px" }} />
                  {taxToggle === "commissionScreen" ? "Add Tax" : ""}
                </Button>
              )}
            </Box>
            <Box>
              <Grid container spacing={gridSpacing}>
                {taxToggle === "commissionScreen" ? (
                  wichOne === "tax" ? (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <label className="react-select-labels z-index-4">Tax</label>
                        <Controller
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                size="small"
                                styles={styles}
                                defaultValue={watch() && watch().taxId}
                                value={value}
                                theme={(x) => ({ ...x, borderRadius: 4 })}
                                className="react-select-no-outline reactSelectFocus z-index-3"
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "Select Tax"}
                                isMulti={false}
                                isClearable={true}
                                closeMenuOnSelect={true}
                                placeholder="Select Tax"
                                onChange={(e, option) => {
                                  if (option.action === "clear") {
                                    onChange([]);
                                  } else {
                                    onChange(e);
                                  }
                                }}
                                options={taxOptions}
                                isSearchable={true}
                              />
                            );
                          }}
                          control={control}
                          rules={{ required: false }}
                          name="taxId"
                        />
                        {/* {errors.taxId && errors.taxId.type === "required" && <ErrorMSG text="Tax is required" />} */}
                      </FormControl>
                      <FormHelperText>This category tax will be applied in all its sub categories.</FormHelperText>
                    </Grid>
                  ) : (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          label={
                            <p className="my-0">
                              Commission (%) <span className="danger mr-0">*</span>
                            </p>
                          }
                          InputLabelProps={{ shrink: true }}
                          {...register("commission", {
                            required: true,
                            validate: {
                              max: (value) => value <= 100
                            }
                          })}
                        />
                        {errors.commission && errors.commission.type === "required" && (
                          <ErrorMSG text="Commission is required" />
                        )}
                        {errors.commission && errors.commission.type === "max" && (
                          <ErrorMSG text="Commission should not be greater than 100% " />
                        )}
                      </FormControl>
                    </Grid>
                  )
                ) : (
                  <>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          label={
                            <p className="my-0">
                              Tax Name <span className="danger mr-0">*</span>
                            </p>
                          }
                          InputLabelProps={{ shrink: true }}
                          {...register("label", {
                            required: true,
                            pattern: {
                              value: /^[^\s]+(?:$|.*[^\s]+$)/,
                              message: "whitespae not allowed"
                            },
                            maxLength: {
                              value: 20,
                              message: "Maximum 20 charachter"
                            }
                          })}
                        />
                        {errors.label && errors.label.type === "required" && <ErrorMSG text="Tax Name is required" />}
                        {errors.label && errors.label.type === "pattern" && <ErrorMSG text="Whitespae not allowed" />}
                        {errors.label && errors.label.type === "maxLength" && (
                          <ErrorMSG text="Tax name should not be greater than length 20" />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          // onWheel={inputScroll}
                          label={
                            <p className="my-0">
                              Tax Percentage <span className="danger mr-0">*</span>
                            </p>
                          }
                          inputProps={{
                            step: 0.01
                          }}
                          InputLabelProps={{ shrink: true }}
                          {...register("taxPercent", {
                            required: true,
                            validate: {
                              max: (value) => parseFloat(value) <= 100
                            }
                          })}
                        />
                        {errors.taxPercent && errors.taxPercent.type === "required" && (
                          <ErrorMSG text="Tax Percentage is required" />
                        )}
                        {errors.taxPercent && errors.taxPercent.type === "max" && (
                          <ErrorMSG text="Percentage should not be greater than 100% " />
                        )}
                      </FormControl>
                    </Grid>
                    {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          label={
                            <p className="my-0">
                              Commission (%) <span className="danger mr-0">*</span>
                            </p>
                          }
                          InputLabelProps={{ shrink: true }}
                          {...register("commission", {
                            required: true,
                            validate: {
                              max: (value) => value <= 100
                            }
                          })}
                        />
                        {errors.commission && errors.commission.type === "required" && (
                          <ErrorMSG text="Commission is required" />
                        )}
                        {errors.commission && errors.commission.type === "max" && (
                          <ErrorMSG text="Commission should not be greater than 100% " />
                        )}
                      </FormControl>
                    </Grid> */}
                  </>
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
                setTaxToggle("commissionScreen");
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isTaxAdded}
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              {isTaxAdded ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </React.Fragment>
  );
}
