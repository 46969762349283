import React from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { ButtonLoader } from "./ButtonLoader";
import { FileExport } from "./icons";

const ExportButton = ({ dataLength = 0, loading = false, text = "Export", onClick, sx = {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ButtonLoader
      full={false}
      sx={{ gap: loading ? "10px" : 0, ...sx }}
      text={text}
      loading={loading}
      color={dataLength > 0 ? "primary" : "secondary"}
      disableTouchRipple={!dataLength > 0}
      //   variant="outlined"
      disabled={!dataLength > 0}
      startIcon={
        <FileExport
          height="20px"
          width={"20px"}
          color={dataLength === 0 || dataLength < 0 || loading ? "grey" : colors.primary[900]}
        />
      }
      onClick={onClick}
    />
  );
};

export default ExportButton;
