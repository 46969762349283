import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import { SupportType } from "./type";

export const fetchSupports = (body) => async (dispatch) => {
  try {
    dispatch({ type: SupportType.GET_SUPPORT_LIST_REQUEST });
    let apiEndpoint = `${API_END_POINTS.supports}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: SupportType.GET_SUPPORT_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: SupportType.GET_SUPPORT_LIST_FAIL, error: message });
  }
};

export const fetchSupportsExport = (body) => async (dispatch) => {
  try {
    let apiEndpoint = `${API_END_POINTS.supports}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return data;
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
  }
};

export const getSingleSupport = (id) => async (dispatch) => {
  try {
    dispatch({ type: SupportType.GET_SINGLE_SUPPORT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.supportView}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${id}`);
    return dispatch({
      type: SupportType.GET_SINGLE_SUPPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: SupportType.GET_SINGLE_SUPPORT_FAIL, error: message });
  }
};

// get support dashboard
export const getDasboardSupportData = () => async (dispatch) => {
  try {
    dispatch({ type: SupportType.GET_DASHBOARD_SUPPORT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getDashboardSupport}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: SupportType.GET_DASHBOARD_SUPPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: SupportType.GET_DASHBOARD_SUPPORT_FAIL, error: message });
  }
};

export const addMessageToSupport = (body) => async (dispatch) => {
  try {
    dispatch({ type: SupportType.ADD_MESSAGE_SUPPORT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addMessageSupport}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: SupportType.ADD_MESSAGE_SUPPORT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: SupportType.ADD_MESSAGE_SUPPORT_FAIL, error: message });
  }
};
