import { Card } from "@mui/material";
import React from "react";

export default function MyCard({ children, sx, bgcolor, ...rest }) {
  return (
    <Card sx={{ bgcolor: `${bgcolor ? bgcolor : "white"}`, boxShadow: 3, py: 3, px: 2, mt: 3, ...sx }} {...rest}>
      {children}
    </Card>
  );
}
