// import CryptoAES from "crypto-js/aes";
// import CryptoENC from "crypto-js/enc-utf8";

import moment from "moment";
import noImage from "../media/Images/no-photo-available.png";

// const secretKey = "sOV987RaviNWjR0AadD4rdxs01lwH365";

// export const tz = (() => {
//   if (Intl && Intl.DateTimeFormat()) return Intl.DateTimeFormat().resolvedOptions().timeZone;
//   else return "America/New_York";
// })();

// export const currencyFormat = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   minimumFractionDigits: 2
// }).format;

// export const formatPhoneNumber = (phoneNumberString) => {
//   const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
//   const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     const intlCode = match[1] ? "+1 " : "";
//     const formattedNumber = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
//     if (!formattedNumber) return phoneNumberString;
//     return formattedNumber;
//   }else{
//   return phoneNumberString;
//   }
// };

export const styles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    textTransform: "capitalize",
    backgroundColor: isDisabled ? undefined : isSelected ? "#009393" : isFocused ? "#e5e4e4" : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "white" : isFocused ? "#000" : "#000",
    cursor: isDisabled ? "not-allowed" : "pointer"
  }),
  control: (styles) => ({
    ...styles,
    fontWeight: 400,
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87) !important",
    padding: "4px 5px",
    cursor: "pointer",
    backgroundColor: "transparent"

    // borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    // ":active": { boxShadow: "none", border: "none" }
  })
};

export function statusFilter(status) {
  const themePrimary = "#E32C2B40";
  const secondary = "#00939340";
  const themeGray = "#6c757d40";
  const success = "#29A74540";
  const warning = "#FFC00840";

  switch (status) {
    case "orderPlaced":
      return { name: "Order Placed", color: secondary };
    case "outOfDelivery":
      return { name: "Out Of Delivery", color: secondary };
    case "delivered":
      return { name: "Delivered", color: success };
    case "cancelled":
      return { name: "Cancelled", color: themePrimary };
    case "returned":
      return { name: "Returned", color: themePrimary };
    case "replaced":
      return { name: "Replaced", color: themeGray };
    case "returnRejected":
      return { name: "Return Rejected", color: themePrimary };
    case "replacementRejected":
      return { name: "replacement Rejected", color: themePrimary };
    case "requested":
      return { name: "Requested", color: warning };
    case "approved":
      return { name: "Approved", color: success };
    case "pickup":
      return { name: "Pickup", color: secondary };
    case "received":
      return { name: "Received", color: success };
    case "processing":
      return { name: "Processing", color: secondary };
    case "rejected":
      return { name: "Rejected", color: secondary };
    case "refunded":
      return { name: "Refunded", color: themeGray };
    case "driverConfirmed":
      return { name: "Driver Confirmed", color: secondary };
    case "awaitingForDelivery":
      return { name: "Awaiting For Delivery", color: warning };
    case "pending":
      return { name: "Pending", color: secondary };
    case "failed":
      return { name: "Failed", color: themePrimary };
    case "refundInitiated":
      return { name: "Refund Initiated", color: warning };
    case "refundFailed":
      return { name: "Refund Failed", color: warning };
    case "inQueue":
      return { name: "In Queue", color: themeGray };
    case "awaiting":
      return { name: "Awaiting", color: warning };
    case "expired":
      return { name: "Expired", color: themePrimary };
    case "prepared":
      return { name: "Prepared", color: secondary };
    case "driverConfirmationPending":
      return { name: "Driver Confirmation Pending", color: warning };
    case "driverConfirmationCompleted":
      return { name: "Driver Confirmation Completed", color: success };
    case "offQueue":
      return { name: "Off Queue", color: themeGray };
    case "locationReceived":
      return { name: "Location Received", color: secondary };
    case "driverApproved":
      return { name: "Driver Approved", color: themePrimary };
    case "driverOnTheWay":
      return { name: "Driver On The Way", color: secondary };
    case "driverPickedUp":
      return { name: "Driver Picked Up", color: secondary };
    case "refundProcessing":
      return { name: "Refund Processing", color: secondary };
    case "returnFailed":
      return { name: "Return Failed", color: themePrimary };
    case "replacementFailed":
      return { name: "Replacement Failed", color: themePrimary };
    case "locationReceivedAndPickup":
      return { name: "Location Received And Pickup", color: secondary };
    default:
      return { name: "N/A", color: "" };
  }
}

export const dependentRoles = {
  category_add: "category_list",
  category_view: "category_list",
  category_edit: "category_list",
  category_delete: "category_list",
  brand_add: "brand_list",
  brand_view: "brand_list",
  brand_edit: "brand_list",
  brand_delete: "brand_list",
  store_add: "store_list",
  store_view: "store_list",
  store_edit: "store_list",
  category_product_tree: ["store_list", "category_product_tree", "store_view"],
  store_delete: "store_list",
  store_tax_comm_edit: ["store_list", "store_tax_comm_view", "store_view"],
  store_tax_comm_view: ["store_list", "store_tax_comm_view", "store_view"],
  assign_subProduct: ["store_list", "store_view", "category_product_tree"],
  support_reply: ["support_list", "support_reply", "support_view"],
  support_view: ["support_list", "support_reply", "support_view"],
  support_dashboard: "support_list",
  driver_add: "driver_list",
  driver_view: "driver_list",
  driver_edit: ["driver_list", "driver_view", "driver_edit"],
  driver_delete: "driver_list",
  user_add: "user_list",
  user_view: "user_list",
  user_edit: "user_list",
  user_delete: "user_list",
  tax_add: "tax_list",
  tax_view: "tax_list",
  tax_edit: "tax_list",
  tax_delete: "tax_list",
  customer_add: "customer_list",
  customer_change_status: ["customer_list", "customer_view", "customer_change_status"],
  customer_view: "customer_list",
  customer_delete: "customer_list",
  inventory_view: "inventory_list",
  order_view: "order_list",
  cancel_order: ["order_list", "order_view"],
  product_add: "product_list",
  product_view: "product_list",
  product_edit: "product_list",
  product_delete: "product_list",
  sub_product_list: ["product_list", "product_view"],
  sub_product_edit: ["product_list", "product_view", "sub_product_list", "product_edit"],
  sub_product_add: ["sub_product_list", "product_view", "product_list", "product_edit"],
  sub_product_view: ["sub_product_list", "product_view", "product_list", "product_edit"],
  sub_product_delete: ["sub_product_list", "product_view", "product_list", "product_edit"],
  statement_generate: ["statement_all_view"],
  statement_payment: ["statement_all_view"],
  Assign_Product_View: ["order_list", "order_view"]
};

// export const searchFilterRestricted = (e) => {
//   let flag = e.key.search(/^[a-zA-Z0-9]+$/) === -1 ? false : true;
//   if (!flag && ![".", "@", " "].includes(e.key)) e.preventDefault();
// };

// export const onlyNumbers = (string) => {
//   if (string !== "") {
//     var letters = /[0-9]/g;
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const onlyAlpha = (string) => {
//   if (string !== "") {
//     var letters = /[a-z]/gi;
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const numberWithSpecialChar = (string, char) => {
//   if (string !== "") {
//     var letters = new RegExp("[0-9" + char + "]", "g");
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const alphaWithSpecialChar = (string, char) => {
//   if (string !== "") {
//     var letters = new RegExp("[a-z" + char + "]", "gi");
//     var str = string.match(letters);
//     return str !== null ? str.toString().replace(/,/g, "") : "";
//   } else {
//     return "";
//   }
// };

// export const capitalizeEachWord = (string) => {

//   let arr = string.split(" ");

//   for (var i = 0; i < arr.length; i++) {
//       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

//   }

//   let str2 = arr.join(" ");
//   return str2

// }

// export const checkCapLock = () => {

//   window.addEventListener('keydown', detectCapsLock)
//   window.addEventListener('keyup', detectCapsLock)

//   function detectCapsLock(e) {
//     if (typeof e.getModifierState == "function" && e.getModifierState('CapsLock')) {
//       return true
//     } else {
//       return false
//     }
//   }

// }

// export const decryptResponse = (data) => {
//   try {
//     if (process.env.REACT_APP_ENV === "development") {
//       return data;
//     } else {
//       var bytes = CryptoAES.decrypt(data, secretKey);
//       return JSON.parse(bytes.toString(CryptoENC));
//     }
//   } catch (error) {
//     throw error;
//   }
//   // if (process.env.REACT_APP_ENV === "development") {
//   //   return data;
//   // } else {
//   //   var bytes = CryptoAES.decrypt(data, secretKey);
//   //   return JSON.parse(bytes.toString(CryptoENC));
//   // }
// };

// export const clearLocalStorage = (arrList) => {
//   if(arrList == null){
//     localStorage.clear()
//   }
//   else{
//     if(arrList.length > 0){
//       for(let x of arrList){
//         localStorage.removeItem(x)
//       }
//     }
//   }
// }

// export const getLogin = () => {
//   const companyName = localStorage.getItem("companyName")
//   if(companyName != null){
//     return true
//   }
//   else{
//     return false
//   }
// }

// export const GETCITYSTATEAPI = "https://www.prominentgames.com/us_zip_city/get.php";

export function setCookie(name, value, expires) {
  const ex = expires * 1000 + new Date().getTime();
  document.cookie = `${name}=${value}; expires=${new Date(ex)}`;
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document?.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export function jsonToQueryString(json) {
  return Object.keys(json)
    .map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
    .join("&");
}

export function todayTomorrow(value) {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  if (
    moment(value).format("yyyy-MM-D") === moment(date).format("yyyy-MM-D") ||
    moment(value).format("yyyy-MM-D") === `${year}-${month + 1}-${day + 1}`
  ) {
    return true;
  }
  return false;
}

export const inputScroll = (e) => {
  e.target.blur();
  e.stopPropagation();
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export async function getLatLong(address) {
  let lat, long;
  const geocoder = await new window.google.maps.Geocoder();
  await geocoder.geocode({ address }, function (results, status) {
    if (status === window.google.maps.GeocoderStatus.OK) {
      lat = results[0].geometry.location.lat();
      long = results[0].geometry.location.lng();
    }
  });
  return { lat, long };
}

export const convertToDollar = (value) => {
  return `$${value}`;
};

export const CommanFormatDate = "MM/DD/yyyy";

export const formatDate = (date) => {
  return moment(date).format(CommanFormatDate);
};

export const onImageError = (e) => {
  e.target.src = noImage;
};

export const setTitle = (text) => {
  if (document) {
    document.title = text;
  }
};

export function deepFreeze(o) {
  Object.freeze(o);
  Object.getOwnPropertyNames(o).forEach(function (prop) {
    if (
      o[prop] !== null &&
      (typeof o[prop] === "object" || typeof o[prop] === "function") &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });
  return o;
}

export function currencyFormate(price = 0, currency = "USD", fraction = 2) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: fraction
  }).format(price);

  return formatter;
}
export function addSpaceBeforeCapital(str) {
  return str.replace(/([A-Z])/g, " $1").trim();
}

export function calculatePercentage(value, total) {
  if (total === 0) {
    return 0;
  } else {
    const percentage = (value / total) * 100;
    return percentage;
  }
}
