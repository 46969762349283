import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
const middleware = {
  immutableCheck: false,
  serializableCheck: false,
  thunk: true
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(middleware),
  devTools: process.env.NODE_ENV !== "production"
});

export default store;
