import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor from "../../../utils/JsonInterceptor";
import {
  FETCH_TAX,
  GET_TAXS,
  TAXES_FAIL,
  TAXES_REQUEST,
  TAXES_SUCCESS,
  TAX_ADD_FAIL,
  TAX_ADD_REQUEST,
  TAX_ADD_SUCCESS,
  TAX_DELETE_REQUEST,
  TAX_DELETE_SUCCESS,
  TAX_FAIL,
  TAX_REQUEST,
  TAX_UPDATE_FAIL,
  TAX_UPDATE_REQUEST,
  TAX_UPDATE_SUCCESS
} from "./type";
// import useEncryption from "../../components/Common/useEncryption";

export const fetchTaxs = (body) => async (dispatch) => {
  try {
    dispatch({ type: TAX_REQUEST });
    let apiEndpoint = `${API_END_POINTS.taxs}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: GET_TAXS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: TAX_FAIL, error: message });
  }
};
export const fetchTaxsExport = (body) => async (dispatch) => {
  try {
    let apiEndpoint = `${API_END_POINTS.taxs}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return data;
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return message;
  }
};

export const addTax = (body) => async (dispatch) => {
  try {
    dispatch({ type: TAX_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addTax}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: TAX_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: TAX_ADD_FAIL, error: message });
  }
};

export const getTax = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_TAX,
      payload: id
    });
  } catch (error) {}
};

export const updateTax = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: TAX_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editTax}`;
    const { data } = await JsonInterceptor.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: TAX_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: TAX_UPDATE_FAIL, error: message });
  }
};

export const deleteTax = (id) => async (dispatch) => {
  try {
    dispatch({ type: TAX_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteTax}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);

    return dispatch({
      type: TAX_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: TAX_UPDATE_SUCCESS, error: message });
  }
};

export const fetchDropdownTaxs = () => async (dispatch) => {
  try {
    dispatch({ type: TAXES_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getTaxesEndpoint}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`);
    return dispatch({
      type: TAXES_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: TAXES_FAIL, error: message });
  }
};
