import { Table, TableContainer, TableFooter, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import TableHeading from "./TableHeading";
import TableLoader from "./TableLoader";
import TableData from "./TableBody";
import Pagination from "./Pagination";
import { tokens } from "../../theme";

const CustomTable = ({
  cols,
  data,
  total,
  dataFilter,
  setDataFilter,
  onBatchChange,
  isOnlyTable,
  isLoading,
  manualColor,
  tableHeight,
  tableFooter,
  noDataText = "",
  skeletonLoader = false,
  sx = {}
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const BatchChange = (e, newPage) => {
    if (onBatchChange) {
      onBatchChange(e, newPage);
      return;
    }
    const newOffset = ((newPage + 1) * dataFilter?.limit) % total;
    setDataFilter({ ...dataFilter, skip: newOffset, page: newPage + 1 });
  };
  return (
    <Fragment>
      <TableContainer
        sx={{
          borderRadius: "4px",
          height: tableHeight || "520px",
          minHeight: "100%",
          position: "relative",
          background: "#e9ecef",
          ...sx
        }}
      >
        <Table size="small">
          <TableHeading
            colors={colors}
            manualColor={manualColor}
            cols={cols}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
          />

          {skeletonLoader && isLoading ? (
            <TableLoader cols={cols} />
          ) : (
            <TableData loading={isLoading} data={data} noDataText={noDataText} cols={cols} />
          )}
          {tableFooter && data?.length > 0 && <TableFooter>{tableFooter}</TableFooter>}
        </Table>
      </TableContainer>
      {!isOnlyTable && (
        <Pagination
          count={total !== undefined ? total : 0}
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          total={total}
          onBatchChange={BatchChange}
        />
      )}
    </Fragment>
  );
};

export default CustomTable;
