import { useState } from "react";
// import { Container, Input, Card, Button, CardBody, CardTitle } from "reactstrap";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import CryptoJS from "crypto-js";
// import Loader from "./loader";
import JSONFormatter from "json-formatter-js";
import { Box, Button, Card, CardContent, FormControl, Grid, TextareaAutosize, Typography } from "@mui/material";
import GlobalLoader from "./GlobalLoader";
const secretKey = process.env.REACT_APP_SECRET_KEY;
const DecryptComponent = (props) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const decryptData = () => {
    document.getElementById("test").innerHTML = "";
    setIsLoading(true);
    try {
      var bytes = CryptoAES.decrypt(data, secretKey);
      const formatter = new JSONFormatter(JSON.parse(bytes.toString(CryptoENC)));
      document.getElementById("test").append(formatter.render());
      formatter.openAtDepth("Infinity");
    } catch (error) {
      document.getElementById("test").innerHTML = "Somthing Wrong";
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const decryptUnityResponse = () => {
    document.getElementById("test").innerHTML = "";
    setIsLoading(true);
    try {
      var keyBytes = CryptoJS.PBKDF2(secretKey, "99@pro89@85@11$%", { keySize: 48 / 4, iterations: 1000 });
      var key = new CryptoJS.lib.WordArray.init(keyBytes.words, 32);
      var iv = new CryptoJS.lib.WordArray.init(keyBytes.words.splice(32 / 4), 16);
      var decrypted = CryptoJS.AES.decrypt(data, key, { iv: iv });
      const formatter = new JSONFormatter(JSON.parse(decrypted.toString(CryptoJS.enc.Utf16LE)));
      document.getElementById("test").append(formatter.render());
      formatter.openAtDepth("Infinity");
    } catch (error) {
      document.getElementById("test").innerHTML = "Somthing Wrong";
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const encryptData = () => {
    document.getElementById("test").innerHTML = "";
    setIsLoading(true);
    try {
      var bytes = CryptoAES.encrypt(JSON.stringify(data), secretKey).toString();
      document.getElementById("test").innerHTML = bytes;
    } catch (error) {
      document.getElementById("test").innerHTML = "Somthing Wrong";
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const encryptUnityResponse = () => {
    document.getElementById("test").innerHTML = "";
    setIsLoading(true);
    try {
      var keyBytes = CryptoJS.PBKDF2(secretKey, "99@pro89@85@11$%", { keySize: 48 / 4, iterations: 1000 });
      var key = new CryptoJS.lib.WordArray.init(keyBytes.words, 32);
      var iv = new CryptoJS.lib.WordArray.init(keyBytes.words.splice(32 / 4), 16);
      var encrypt = CryptoJS.enc.Utf16LE.parse(JSON.stringify(data));
      document.getElementById("test").innerHTML = CryptoJS.AES.encrypt(encrypt, key, { iv: iv }).toString();
    } catch (error) {
      document.getElementById("test").innerHTML = "Somthing Wrong";
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <Box width="100%" p="2rem" className="sectionPad">
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h4" fontWeight="bold" mb="8px">
              Enter Encrypt String:
            </Typography>
            <FormControl fullWidth>
              <TextareaAutosize
                className="decryptTextarea"
                placeholder="Enter string to Decrypt"
                name="text"
                id="exampleText"
                value={data}
                onChange={(e) => setData(e.target.value)}
                minRows={1}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="16px" mt="16px">
              <Box display="flex" justifyContent="flex-start" alignItems="center" gap="8px">
                <Button type="button" variant="contained" color="primary" onClick={(e) => encryptData()}>
                  Encrypt
                </Button>
                <Button type="button" variant="outlined" color="primary" onClick={(e) => encryptUnityResponse()}>
                  Unity Encrypt
                </Button>
              </Box>
              <Box display="flex" justifyContent="flex-start" alignItems="center" gap="8px" ml="auto">
                <Button type="button" variant="contained" color="primary" onClick={(e) => decryptData()}>
                  Decrypt
                </Button>
                <Button type="button" variant="outlined" color="primary" onClick={(e) => decryptUnityResponse()}>
                  Unity Decrypt
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {isLoading ? (
            <GlobalLoader />
          ) : (
            <Card sx={{ p: 3 }}>
              <Typography variant="h4" fontWeight="bold" mb="8px">
                Result:
              </Typography>

              <CardContent>
                <Box id="test" sx={{ wordWrap: "break-word" }}></Box>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DecryptComponent;
