import { Box, Grid } from "@mui/material";
import { Header } from "../../components/Header";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import { getLocationStatements, getStatements, viewStatements } from "./redux/action";
import StatementsMainTable from "./statement-tables/StatementsMainTable";
import StatementsViewTable from "./statement-tables/StatementsViewTable";
import SearchBar from "./SearchBar";
const Statement = () => {
  const [reqData, setReqData] = useState({
    status: "",
    page: 1,
    limit: 10,
    search: "",
    fromDate: new Date(),
    toDate: new Date()
  });
  const { statements, isMainStatementsLoading, statementsViewData, isViewStatementsLoading, total, statemenTotal } =
    useSelector((state) => state.statement, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const LOCATION = user.userType === "store";
  const [currentStatementId, setCurrentStatementId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Statements | Doorvenza";
  }, []);

  useEffect(() => {
    !LOCATION
      ? dispatch(getStatements(reqData)).then(() => {
          setCurrentStatementId("");
          dispatch(viewStatements({ statementId: "" }, true));
        })
      : dispatch(getLocationStatements(reqData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reqData]);

  // useEffect(() => {
  //   if (statements && statements.length > 0) {
  //     dispatch(viewStatements({ statementId: statements[statements.length - 1]._id }));
  //   }
  // }, [dispatch, statements]);

  return (
    <Box mx="20px">
      <Grid container spacing={1} mb={3}>
        <Grid item xl={6} lg={6} md={6} sm={7} xs={12}>
          <Header title="STATEMENT REPORT" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={5} xs={12}>
          <SearchBar
            LOCATION={LOCATION}
            reqData={reqData}
            setCurrentStatementId={setCurrentStatementId}
            setReqData={setReqData}
          />
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          gap: 4,
          flexDirection: "column"
        }}
      >
        {!LOCATION && (
          <StatementsMainTable
            location={LOCATION}
            reqData={reqData}
            total={total}
            setReqData={setReqData}
            statements={statements}
            isLoading={isMainStatementsLoading}
            setCurrentStatementId={setCurrentStatementId}
            currentStatementId={currentStatementId}
          />
        )}
        <StatementsViewTable
          reqData={reqData}
          total={statemenTotal}
          setReqData={setReqData}
          location={LOCATION}
          statements={statementsViewData}
          isLoading={isViewStatementsLoading}
          setCurrentStatementId={setCurrentStatementId}
          currentStatementId={currentStatementId}
        />
      </Box>
    </Box>
  );
};

export default Statement;
