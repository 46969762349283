export const DropdownIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="m296-358.46-42.15-42.16L480-626.77l226.15 226.15L664-358.46l-184-184-184 184Z" />
    </svg>
  );
};

export const DropdownUpIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M480-358.46 253.85-584.62 296-626.77l184 184 184-184 42.15 42.15L480-358.46Z" />
    </svg>
  );
};

export const MenuIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M103-212v-94h754v94H103Zm0-221v-94h754v94H103Zm0-221v-95h754v95H103Z" />
    </svg>
  );
};

export const CloseIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="m249-183-66-66 231-231-231-231 66-66 231 231 231-231 66 66-231 231 231 231-66 66-231-231-231 231Z"
      />
    </svg>
  );
};
export const NavigateNextIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
    </svg>
  );
};
export const PDFFillIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M331-431h37v-83h48q15.725 0 26.362-10.638Q453-535.275 453-551v-48q0-15.725-10.638-26.362Q431.725-636 416-636h-85v205Zm37-120v-48h48v48h-48Zm129 120h84q15 0 26-10.638 11-10.637 11-26.362v-131q0-15.725-11-26.362Q596-636 581-636h-84v205Zm37-37v-131h47v131h-47Zm133 37h37v-83h50v-37h-50v-48h50v-37h-87v205ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260ZM140-80q-24 0-42-18t-18-42v-620h60v620h620v60H140Z"
      />
    </svg>
  );
};
export const NoSort = ({ className, height, width, color }) => {
  return (
    <svg
      focusable="false"
      className={className}
      height={height || "25px"}
      width={width || "25px"}
      aria-hidden="true"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99zM9 3 5 6.99h3V14h2V6.99h3z"></path>
    </svg>
  );
};
export const UpSort = ({ className, height, width, color }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M450-160v-526L202-438l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
    </svg>
  );
};
export const DownSort = ({ className, height, width, color }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M480-160 160-480l42-42 248 248v-526h60v526l248-248 42 42-320 320Z" />
    </svg>
  );
};
export const FilterFillIcon = ({ className, width, height, color }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      color={color ? color : "#141414"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M440-160q-17 0-28.5-11.5T400-200v-240L161-745q-14-17-4-36t31-19h584q21 0 31 19t-4 36L560-440v240q0 17-11.5 28.5T520-160h-80Z"
      />
    </svg>
  );
};
export const FileExport = ({ className, width, height, color, ...rest }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      color={color ? color : "#141414"}
      aria-hidden="true"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path>
    </svg>
  );
};
export const DotMenu = ({ className, width, height, color, ...rest }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      color={color ? color : "#141414"}
      aria-hidden="true"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );
};
