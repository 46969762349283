// main root endpoint
export const API_ROOT = process.env.REACT_APP_BACKEND_URI;

// all api end points
export const API_END_POINTS = {
  LOGIN: "api/v2/auth/login",
  authCheck: "api/v2/auth/check",
  authRefreshCaptcha: "api/v2/auth/refresh-captcha",
  authCheckCaptcha: "api/v2/auth/check-captcha",
  changePassword: "api/v2/auth/changePassword",
  categories: "api/v2/category/list",
  addCategory: "api/v2/category/create",
  editCategory: "api/v2/category/edit",
  deleteCategoryEndpoint: "api/v2/category/delete",
  updateReturnStatusCategory: "api/v2/category/update-return-status",
  treeCategory: "api/v2/category/category-tree",
  taxs: "api/v2/tax/get",
  deleteTax: "api/v2/tax/delete",
  updateCategoryStatus: "api/v2/category/update-status",
  addTax: "api/v2/tax/add",
  editTax: "api/v2/tax/edit",
  getTaxesEndpoint: "api/v2/tax/dropdown",
  getSingleCategoryEndpoint: "/api/v2/category/get",
  drivers: "api/v2/driver/list",
  addDriver: "api/v2/driver/add",
  editDriver: "api/v2/driver/edit",
  getDriversEndpoint: "api/v2/driver/dropdown",
  deleteDriver: "api/v2/driver/delete",
  brands: "api/v2/brand/list",
  addBrand: "api/v2/brand/add",
  editBrand: "api/v2/brand/edit",
  deleteBrand: "api/v2/brand/delete",
  brandsData: "api/v2/brand/dropdown",
  products: "api/v2/product/list1",
  addProduct: "api/v2/product/create",
  editProduct: "api/v2/product/edit",
  getProduct: "api/v2/product/show",
  deleteProduct: "api/v2/product/delete",
  singleProduct: "api/v2/subProduct/list",
  addSingleProduct: "api/v2/subProduct/save-edit",
  editSingleProduct: "api/v2/subProduct/edit",
  deleteSingleProduct: "api/v2/subProduct/delete",
  users: "api/v2/user/list",
  addUser: "api/v2/user/create",
  editUser: "api/v2/user/edit",
  deleteUser: "api/v2/user/delete",
  userRole: "api/v2/user/listrole",
  assignRole: "api/v2/user/assignrole",
  customer: "api/v2/customer/list",
  addCustomer: "api/v2/customer/create",
  editCustomer: "api/v2/customer/edit",
  deleteCustomer: "api/v2/customer/delete",
  getCustomer: "api/v2/customer/show",
  editCustomerAddress: "api/v2/customer/addressedit",
  vendors: "api/v2/vendor/list",
  addVendor: "api/v2/vendor/add",
  editVendor: "api/v2/vendor/edit",
  deleteVendor: "api/v2/vendor/delete",
  inventory: "api/v2/inventory/list",
  addInventory: "api/v2/inventory/create",
  editInventory: "api/v2/inventory/edit",
  deleteInventory: "api/v2/inventory/delete",
  singleInventory: "api/v2/inventory/get",
  productlist: "api/v2/product/dropdown",
  subproductlist: "api/v2/subProduct/dropdown",
  vendorlist: "api/v2/vendor/dropdown",
  lastInventory: "api/v2/subProduct/showInventory",
  orders: "api/v2/order/list",
  singleOrder: "/api/v2/order/view",
  updateOrderStatus: "api/v2/order/update-status",
  customerDropdown: "api/v2/user/dropdown",
  searchInventory: "api/v2/order/search-inventory",
  editStatusEndpoint: "api/v2/status",
  deleteProductFromAPI: "api/v2/product/delete-image",
  pdfView: "api/v2/order/view",
  fetchDashboard1: "api/v2/dashboard/dashboard-data",
  fetchNotification: "api/v2/dashboard/get-notification",
  fetchCompanies: "api/v2/user/dropdown",
  getAdminAddress: "api/v2/customer/get-admin-address",
  getProfile: "api/v2/user/get",
  stores: "api/v2/store/list",
  addStore: "api/v2/store/add",
  editStore: "api/v2/store/edit",
  deleteStore: "api/v2/store/delete",
  getSingleStore: "api/v2/store/view",
  getStoresDropdown: "api/v2/store/storeDropdown",
  mainOrders: "api/v2/main-order/list",
  addMainOrders: "api/v2/main-order/add",
  editMainOrder: "api/v2/main-order/edit",
  deleteMainOrder: "api/v2/main-order/delete",
  getSpecifications: "api/v2/category/specification-list",
  getSpecificationsByCategory: "api/v2/category/specification-by-category",
  addSingleProductImageEndpoint: "api/v2/product/add-images",
  addSingleSubProductImageEndpoint: "api/v2/subProduct/add-images",
  getStoreProductsEndpoint: "api/v2/category/category-product-tree",
  addStoreProduct: "api/v2/store/add-subProduct",
  supports: "api/v2/support/list",
  supportList: "api/v2/support/list",
  supportView: "api/v2/support/get",
  supportDashBoard: "api/v2/support/dashboard",
  getPaymentdetails: "/api/v2/payment/payment-details",
  getDashboardSupport: "/api/v2/support/dashboard",
  addMessageSupport: "/api/v2/support/reply",
  resetPassword: "api/v2/auth/set-password",
  resetPasswordResend: "api/v2/auth/reset-password",
  getCategoryTaxTreeEndpoint: "api/v2/category/tax-flow",
  taxCategoryUpdateEndpoint: "/api/v2/category/edit-tax",
  customersDropdownData: "/api/v2/customer/customerDropdown",
  getStatements: "api/v2/statement/get",
  getLocationStatements: "api/v2/statement/location-statement-list",
  generateStatements: "api/v2/statement/create-statement",
  viewStatements: "api/v2/statement/view",
  viewInvoiceStatements: "api/v2/statement/tree-view",
  payout: "api/v2/statement/payout",
  satamentPDF: "api/v2/statement/pdf",
  getLocationReport: "api/v2/report/store-report",
  getCancelReason: "api/v2/dashboard/reason-dropdown",
  cancelOrder: "api/v2/order/cancel-order",
  returnReplace: "/api/v2/order/return-replace-list",
  getPaymentType: "/api/v2/settings",
  changePaymentType: "/api/v2/settings/update"
};
