import { Dialog, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

export default function MyDialog({ maxWidth, open, handleClose, children, top, disableEscapeKeyDown, sx }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      open={open}
      onClose={() => {
        handleClose();
      }}
      sx={{
        overflowY: "auto",
        "& .MuiDialog-container": { height: "auto" },
        "& .MuiDialog-paper": {
          margin: `${isSmall ? "15px !important" : "30px auto"}`,
          width: `${isSmall ? "calc(100% - 30px)" : "calc(100% - 64px)"}`
        },
        "& .MuiPaper-root": {
          overflowY: "unset",
          maxHeight: "unset !important",
          margin: "30px auto"
        },
        "& .MuiDialogContent-root": { overflowY: "unset" },
        "& .MuiDialogActions-root": {
          bgcolor: "#fff",
          borderTop: 1,
          borderColor: "#d5d4d4",
          bottom: "0",
          padding: "16px 20px",
          borderRadius: "0 0 4px 4px"
        },
        ...sx
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: top ? top : "2%", right: "1%" }}
        onClick={() => {
          handleClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
}
