import { Box, FormControlLabel, Switch, Grid, useMediaQuery } from "@mui/material";
import { Header } from "../../components/Header";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import moment from "moment";
import { toast } from "react-toastify";
import SearchBar from "./SearchBar";
import { updateStatus } from "../category/redux/action";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import MyStoreGridToolbar from "./filter/MyStoreGridToolbar";
import { fetchStores, fetchStoresExport } from "./redux/action";
import { NavLink, useNavigate } from "react-router-dom";
import GetUserRole from "../../utils/GetUserRole";
import { formatPhoneNumber } from "../../utils/common";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import { CommanFormatDate } from "../../utils/custom";
import DropdownMenu from "../../components/DropdownMenu";
import ExportButton from "../../components/ExportButton";
import CustomTable from "../../components/CustomTable";
import ExportToXls from "../../components/ExportFile";

const Store = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let roleVendorEdit = GetUserRole("store_edit");
  let roleCategoryTree = GetUserRole("category_product_tree");
  let store_tax_comm_view = GetUserRole("store_tax_comm_view");
  let locationViewRole = GetUserRole("store_view");
  // main state to store the payload
  const [loading, setLoading] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [reqData, setReqData] = useState({
    key: "_id",
    desc: true,
    limit: 25,
    page: 1,
    search: "",
    fromDate: "",
    toDate: "",
    dateRange: "",
    status: ""
  });
  const { stores, total, isLoading } = useSelector((state) => state.store, shallowEqual);

  useEffect(() => {
    document.title = "Locations | Doorvenza";
  }, []);

  useEffect(() => {
    dispatch(fetchStores(reqData));
  }, [dispatch, reqData]);

  /*
   <<<<================== DELETE VENDOR ==================>>>>
   const [openDelete, setDeleteOpen] = useState(true);
  const [id, setId] = useState("");
   const handleDeleteClose = () => {
     setDeleteOpen(false);
     setId("");
    };
  */

  // columns data to the table
  const columns = () => {
    return [
      {
        title: "Sr. No.",
        width: 60,
        sortable: false,
        render: (row) => <p>{row.id}</p>
      },
      {
        sortKey: "storeName",
        title: "Location Name",
        minWidth: 250,
        flex: 1,
        render: (row) => (
          <NavLink
            to={`${locationViewRole ? `/locations/view/${row._id}` : ""} `}
            style={{
              color: colors.secondary[900],
              textDecoration: "none",
              fontWeight: "600",
              cursor: locationViewRole ? "pointer" : "none"
            }}
          >
            {row?.storeName}
          </NavLink>
        )
      },
      {
        sortKey: "fullName",
        title: "Full Name",
        minWidth: 250,
        flex: 1,
        render: (row) => <span>{row?.fullName}</span>
      },
      {
        sortKey: "phone",
        title: "Location Phone",
        width: 150,
        render: (row) => <span>{row?.phone ? formatPhoneNumber(`+1${row?.phone}`) : "N/A"}</span>
      },

      {
        sortKey: "email",
        title: "Location Email",
        minWidth: 250,
        flex: 1,
        render: (row) => <span>{row?.email ? row?.email : "N/A"}</span>
      },
      {
        sortKey: "ownerPhone",
        title: "Owner Phone",
        width: 150,
        render: (row) => <span>{row?.ownerPhone ? formatPhoneNumber(`+1${row?.ownerPhone}`) : "N/A"}</span>
      },
      {
        sortKey: "ownerEmail",
        title: "Owner Email",
        minWidth: 250,
        flex: 1,
        render: (row) => <span>{row?.ownerEmail ? row?.ownerEmail : "N/A"}</span>
      },
      {
        sortKey: "status",
        title: "Status",
        width: 80,
        type: "text",

        headerAlign: "left",
        align: "left",
        sortable: true,
        render: (row) => (
          <>
            <FormControlLabel
              control={
                <Switch
                  disabled={!roleVendorEdit}
                  checked={row?.status}
                  color="success"
                  onChange={(e) => {
                    dispatch(
                      updateStatus({
                        key: "user",
                        id: row?._id,
                        status: row?.status ? false : true
                      })
                    ).then(async (data) => {
                      if (data && data?.payload?.status) {
                        toast.success(data.payload.message);
                        dispatch(fetchStores(reqData));
                      } else {
                        toast.error(data.error);
                      }
                    });
                  }}
                />
              }
            />
          </>
        )
      },
      {
        // sortKey: "createdAt",
        title: "Created Date",
        width: 100,
        render: (row) => <span>{moment(row?.createdAt).format(CommanFormatDate)}</span>
      },
      {
        title: "Actions",
        width: 80,
        render: (row) => (
          <div className="flex items-center">
            <DropdownMenu
              options={[
                {
                  disabled: roleVendorEdit ? false : true,
                  label: "Edit",
                  icon: (
                    <CreateSharpIcon
                      sx={{
                        width: "20px",
                        height: "20px"
                      }}
                      size="small"
                    />
                  ),
                  onClick: () => {
                    navigate(`/locations/edit/${row?._id}`);
                  }
                },
                {
                  disabled: roleCategoryTree ? false : true,
                  label: "Assign Products",
                  icon: (
                    <InventoryIcon
                      size="small"
                      hidetext="true"
                      recorditemid={row?._id}
                      sx={{
                        width: "20px",
                        height: "20px"
                      }}
                    />
                  ),
                  onClick: () => {
                    navigate(`/locations/tree/${row?._id}`);
                  }
                },
                {
                  disabled: store_tax_comm_view ? false : true,
                  label: "Assign Tax",
                  icon: (
                    <AttachMoneyIcon
                      size="small"
                      hidetext="true"
                      recorditemid={row?._id}
                      sx={{
                        width: "20px",
                        height: "20px"
                      }}
                    />
                  ),
                  onClick: () => {
                    navigate(`/locations/locationTax/${row?._id}`);
                  }
                }
              ]}
              icon
            />
          </div>
        ),
        sticky: true
      }
    ];
  };
  const dataExport = (data = []) => {
    try {
      return data.map((x) => {
        const obj = {
          "Location Name": x?.storeName || "N/A",
          "Full Name": x?.fullName || "N/A",
          "Location Phone": x?.phone || "N/A",
          "Location Email": x?.email || "N/A",
          "Owner Phone": x?.ownerPhone || "N/A",
          "Owner Email": x?.ownerEmail || "N/A",
          Status: x?.status ? "Active" : "Inactive",
          "Created Date": x?.createdAt ? moment(x?.createdAt).format(CommanFormatDate) : "N/A"
        };
        if (locationViewRole) delete obj["Location Name"];
        return obj;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = () => {
    setLoading(true);
    dispatch(fetchStoresExport({ ...reqData, limit: total, page: 1 }))
      .then((res) => {
        if (res?.status) {
          ExportToXls("Locations.xls", dataExport(res?.data?.records));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box mx="20px">
      <Grid container spacing={1}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Header title="LOCATION LIST" />
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <SearchBar reqData={reqData} setReqData={setReqData} />
        </Grid>
      </Grid>
      <Box width="100%" className="pageMainCardBox" textAlign="center">
        <Grid container spacing={gridSpacing}>
          <Grid item xl={6} lg={5} md={4} sm={5} xs={12} sx={{ display: "flex", gap: "5px", mb: 1 }}>
            <ExportButton dataLength={total} loading={loading} onClick={handleExport} />
            <MyStoreGridToolbar reqData={reqData} setReqData={setReqData} />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingBlock: "0" }}>
            <CustomTable
              cols={columns()}
              data={
                stores && stores?.length > 0
                  ? stores?.map((product, i) => {
                      return {
                        id: reqData.limit * reqData.page - reqData.limit + (i + 1),
                        ...product
                      };
                    })
                  : []
              }
              total={total || 0}
              isLoading={isLoading}
              dataFilter={reqData}
              setDataFilter={setReqData}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Store;
