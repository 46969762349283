// Order module types
export const OrderMap = {
  ORDER_REQUEST: "ORDER_REQUEST",
  ORDER_SUCCESS: "ORDER_SUCCESS",
  ORDER_FAIL: "ORDER_FAIL",

  SINGLE_ORDER_REQUEST: "SINGLE_ORDER_REQUEST",
  SINGLE_ORDER_SUCCESS: "SINGLE_ORDER_SUCCESS",
  SINGLE_ORDER_FAIL: "SINGLE_ORDER_FAIL",

  CUSTOMER_DROPDOWN_REQUEST: "CUSTOMER_DROPDOWN_REQUEST",
  CUSTOMER_DROPDOWN_SUCCESS: "CUSTOMER_DROPDOWN_SUCCESS",
  CUSTOMER_DROPDOWN_FAIL: "CUSTOMER_DROPDOWN_FAIL",

  SEARCH_INVENTORY_REQUEST: "SEARCH_INVENTORY_REQUEST",
  SEARCH_INVENTORY_SUCCESS: "SEARCH_INVENTORY_SUCCESS",
  SEARCH_INVENTORY_FAIL: "SEARCH_INVENTORY_FAIL",

  PDF_GET_REQUEST: "PDF_GET_REQUEST",
  PDF_GET_SUCCESS: "PDF_GET_SUCCESS",
  PDF_GET_FAIL: "PDF_GET_FAIL",

  GET_CANCEL_REASON_REQUEST: "GET_CANCEL_REASON_REQUEST",
  GET_CANCEL_REASON_SUCCESS: "GET_CANCEL_REASON_SUCCESS",
  GET_CANCEL_REASON_FAIL: "GET_CANCEL_REASON_FAIL",
  CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAIL: "CANCEL_ORDER_FAIL",

  RETURNREPLACE_REQUEST: "RETURNREPLACE_REQUEST",
  RETURNREPLACE_SUCCESS: "RETURNREPLACE_SUCCESS",
  RETURNREPLACE_FAIL: "RETURNREPLACE_FAIL"
};
