import { useTheme } from "@emotion/react";
import { IconButton } from "@mui/material";
import React from "react";
import { tokens } from "../theme";

export default function CustomIconButton({ onClick, children, bgColor, className, sx, p = "" }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <IconButton
      className={className}
      onClick={onClick}
      sx={{
        ...sx,
        backgroundColor: bgColor ? bgColor : colors.themeGray[200],
        transition: "all 0.2s ease-in-out",
        borderRadius: 1,
        p: p || "8px"
      }}
    >
      {children}
    </IconButton>
  );
}
