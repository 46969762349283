import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { viewStatements } from "../redux/action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Box, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { PaymentStatusLabel } from "../../../utils/LabalColor";
import { CommanFormatDate } from "../../../utils/custom";
import { VisibilityOff } from "@mui/icons-material";
import TopStatusFilter from "../filter/TopStatusFilter";
import CustomTable from "../../../components/CustomTable";

export default function StatementsMainTable({
  statements,
  isLoading,
  setCurrentStatementId,
  currentStatementId,
  reqData,
  setReqData,
  total,
  location
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const dispatch = useDispatch();
  const columns = () => {
    return [
      {
        title: "ID",
        width: 60,
        render: (row) => (
          <Typography color={colors.secondary[900]} fontWeight="bold">
            {row.id}
          </Typography>
        )
      },
      {
        title: "Generated Date",
        minWidth: 250,
        render: (row) => (
          <Typography color={colors.secondary[900]} fontWeight="bold">
            {moment(row.generatedDate).format(CommanFormatDate)}
          </Typography>
        )
      },
      {
        title: "Status",
        minWidth: 250,
        render: (row) => <PaymentStatusLabel status={row.status} />
      },
      {
        title: "View",
        minWidth: 0,

        render: (row) => (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={() => {
              setCurrentStatementId(row._id);
              dispatch(viewStatements({ statementId: row._id })).then(async (data) => {
                if (data && data?.payload?.status) {
                  toast.success(data.payload.message);
                }
              });
            }}
          >
            {currentStatementId === row._id ? (
              <RemoveRedEyeIcon sx={{ fontSize: "24px", color: "#707070" }} />
            ) : (
              <VisibilityOff />
            )}
          </IconButton>
        )
      }
    ];
  };
  return (
    <Box width="100%" className="pageMainCardBox" textAlign="center">
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ display: "flex", gap: "5px", mb: 1 }}>
          <TopStatusFilter reqData={reqData} setReqData={setReqData} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingBlock: "0" }}>
          <CustomTable
            sx={{ minHeight: "auto" }}
            tableHeight="200px"
            cols={columns()}
            data={
              statements && statements?.length > 0
                ? statements?.map((product, i) => {
                    return {
                      id: reqData.limit * reqData.page - reqData.limit + (i + 1),
                      ...product
                    };
                  })
                : []
            }
            total={total || 0}
            isLoading={isLoading}
            dataFilter={reqData}
            setDataFilter={setReqData}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
