import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { addProduct, getSingleProduct, updateProduct } from "../product/redux/action";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ErrorMSG from "../../components/ErrorMSG";
import { toast } from "react-toastify";
import ProductTabs from "./ProductTabs";
import JoditEditor from "jodit-react";
import { tokens } from "../../theme";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CategoryAdd from "../category/CategoryAddModal";
import TaxAdd from "../tax/TaxAddModal";
import SubProductTable from "./SubProductTable";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { InternalPageHeader } from "../../components/Header";
import FormFields from "./FormFields";
import RequiredFieldLabel from "../../components/RequiredFieldLabel";
import { getCategoryTree, getSpecificationsList } from "../category/redux/action";
import { fetchBrandsData } from "../subproduct/redux/action";
import { getParentCategories } from "../category/getParentid";
import BrandAddModal from "../brand/BrandAddModal";
import CustomIconButton from "../../components/CustomIconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { GET_SPECIFICATIONS_BY_CATEGORY_INITIAL } from "../product/redux/type";

export default function ProductManagement() {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [value, setTab] = useState("one");
  const [open, setOpen] = useState(false);
  const [openTax, setTaxOpen] = useState(false);
  const [openBrand, setBrandOpen] = useState(false);
  const [modifyLoader, setModifyLoader] = useState(false);
  const [specificationsData, setSpecificationsData] = useState([]);
  const [isSpecificationOpen, setIsSpecificationOpen] = useState(false);
  // const [singleLoader, setSingleLoader] = useState(false);
  const [update, setUpdate] = useState(false);

  const [files, setFiles] = useState([]);
  const [sliderFile, setSliderFiles] = useState([]);
  const [newBrand, setNewBrand] = useState("");

  const editor = useRef(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Long Description",
    height: 400,
    allowResizeX: false,
    allowResizeY: false,
    limitWords: 10000,
    limitHTML: true
    // removeButtons: ["bold"]
  };
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const { specificationOptions } = useSelector(
    (state) => (state && state.products && state.products && state?.products ? state?.products : []),
    shallowEqual
  );

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: "",
      status: true,
      categoryId: "",
      productImage: [],
      sliderImage: [],
      longDescription: "",
      shortDescription: "",
      brandId: "",
      productStatus: "",
      specification:
        specificationOptions &&
        specificationOptions.length > 0 &&
        specificationOptions !== undefined &&
        specificationOptions.length > 0
          ? specificationOptions.map((x) => {
              return {
                _id: x._id,
                value: x?.value,
                name: x?.name,
                type: x.name
              };
            })
          : []
    }
  });

  // ******************** specification part start ***************************

  const { fields, append, remove } = useFieldArray({
    control,
    name: "specification" // unique name for your Field Array
  });

  const { specifications } = useSelector(
    (state) => ({
      specifications: state && state.categories && state.categories ? state.categories?.specifications : []
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSpecificationsList());
  }, [dispatch]);

  const specificationTypes = [
    { value: "Other", label: "Other" },
    ...specifications.map((s) => {
      return { value: s._id, label: s.name };
    })
  ];

  useEffect(() => {
    if (specificationOptions) {
      let temp1 =
        specificationOptions.length > 0 && specificationOptions !== undefined && specificationOptions.length > 0
          ? specificationOptions.map((x) => {
              return {
                _id: x._id,
                value: x?.value,
                name: x?.name,
                type: x.name
              };
            })
          : [];

      reset({
        ...watch(),
        specification: temp1
      });
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [reset, specificationOptions]);

  // ******************** specification part end ***************************

  // ******************** brand part start ***************************

  useEffect(() => {
    dispatch(fetchBrandsData());
  }, [dispatch]);

  const { common } = useSelector(
    (state) => ({
      common: state && state?.subProducts ? state?.subProducts : []
    }),
    shallowEqual
  );
  const { brandsData } = common;

  const brandsOptions =
    brandsData &&
    brandsData.length > 0 &&
    brandsData.map((e) => {
      return {
        label: e.brandName,
        value: e._id
      };
    });

  const setBrandValue = (value) => {
    if (!brandsOptions) return;
    brandsOptions.push(value);
    if (!value) {
      setValue("brandId", { label: "", value: "" });
    } else {
      setValue("brandId", value);
    }
  };

  // ******************** brand part end ***************************

  // ******************** submit handler part start ***************************
  const onSubmit = async (data) => {
    let formDataInfo = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      switch (key) {
        case "specification":
          formDataInfo.append(
            "specification",
            JSON.stringify(
              value.map((x) => {
                return {
                  _id: x._id,
                  name: x?.name,
                  value: x?.value
                };
              })
            )
          );

          break;
        case "productImage":
          if (files.length > 0)
            files.map((x) => {
              return formDataInfo.append("productImage[]", x);
            });
          break;
        case "sliderImage":
          if (sliderFile.length > 0)
            sliderFile.map((x) => {
              return formDataInfo.append("sliderImage[]", x);
            });
          break;
        case "brandId":
          if (value?.value) {
            formDataInfo.append(key, value.value);
          }
          break;
        case "productStatus":
          formDataInfo.append("productStatus", value === "None" ? "" : value);
          break;
        default:
          formDataInfo.append(key, value);
          break;
      }
    });

    if (Object.keys(errors).length === 0) {
      if (id) {
        setModifyLoader(true);
        await dispatch(updateProduct(formDataInfo, id)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              toast.success(data.payload?.message);
              setModifyLoader(false);
              user && user?.userRole?.includes("sub_product_add") && setTab("two");
            }
          } else {
            setModifyLoader(false);
            toast.error(data.error);
          }
        });
      } else {
        setModifyLoader(true);
        await dispatch(addProduct(data.productImage && formDataInfo)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              toast.success(data.payload.message);
              navigate(`/products/edit/${data?.payload?.data}`);
              setModifyLoader(false);
              user && user?.userRole?.includes("sub_product_add") && setTab("two");
            }
          } else {
            setModifyLoader(false);
            toast.error(data.error);
          }
        });
      }
    }
  };

  // ******************** submit handler part end ***************************

  // ******************** category part start ***************************

  // eslint-disable-next-line no-unused-vars
  const [categoryValue, setCategoryValue] = useState("");
  const [productDetails, setProductDetails] = useState(null);
  const singleUpdate = async (name, value) => {
    let formDataInfo = new FormData();
    formDataInfo.append(name, value);
    await dispatch(updateProduct(formDataInfo, id)).then(async (data) => {
      if (data && data.payload) {
        if (data && data.payload.status) {
          toast.success(data.payload?.message);
          if (name === "sliderImage") {
            setUpdate(!update);
          }
        }
      } else {
        toast.error(data.error);
      }
    });
  };

  const { categoriesTreeList } = useSelector(
    (state) => ({
      categoriesTreeList:
        state && state.categories && state.categories.categoriesTreeList ? state.categories.categoriesTreeList : []
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCategoryTree({ flag: false }));
  }, [dispatch]);

  const getPCategories = async (id, categoryData) => {
    if (id) {
      const parentCategories = await getParentCategories(categoryData ? categoryData : categoriesTreeList, id);
      if (parentCategories !== null) {
        setCategoryValue(parentCategories.reverse());
      }
    }
  };

  useEffect(() => {
    if (id) {
      // setSingleLoader(true);
      dispatch(getSingleProduct(id)).then(async (data) => {
        const product = await data.payload.data.productShow;
        setProductDetails(product);

        let brandData = {
          value: product?.brandData?._id,
          label: product?.brandData?.brandName
        };

        let temp1 =
          product.specification.length > 0 && product.specification !== undefined && product.specification.length > 0
            ? product.specification.map((x) => {
                return {
                  _id: x._id,
                  value: x?.value,
                  name: x?.name,
                  type: x.name
                };
              })
            : [];

        await reset({
          name: watch().name ? watch().name : product?.name,
          status: product?.status,
          categoryId: product?.categoryId[0]?.status === true ? product?.categoryId[0]._id : "",
          brandId: brandData,
          productStatus: product?.productStatus ? product?.productStatus : "None",
          sliderImage: product && product?.sliderImage.length > 0 && product?.sliderImage,
          productImage:
            product && product?.productImage ? product?.productImage.length > 0 && product?.productImage : [],
          longDescription: watch().longDescription ? watch().longDescription : product?.longDescription,
          shortDescription: watch().shortDescription ? watch().shortDescription : product?.shortDescription,
          specification: temp1
        });
        // setSingleLoader(false);
      });

      // }
    } else {
      // reset(defaultState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update]);

  useEffect(
    () => {
      if (productDetails !== null && categoriesTreeList.length > 0)
        getPCategories(productDetails?.categoryId[0]._id && productDetails?.categoryId[0]._id);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productDetails, categoriesTreeList]
  );
  useEffect(() => {
    if (location?.pathname === "/products/add") {
      dispatch({
        type: GET_SPECIFICATIONS_BY_CATEGORY_INITIAL,
        payload: []
      });
    }
  }, [dispatch, location]);

  // ******************** category part end ***************************

  // ******************** toggle open handlers start ***************************

  const handleClose = async () => {
    await dispatch(getCategoryTree({ flag: false }));
    setOpen(false);
  };
  const handleTaxClose = () => {
    setTaxOpen(false);
  };
  const handleBrandClose = () => {
    setBrandOpen(false);
  };
  const handleChange2 = (event, newValue) => {
    setParams({ tab: newValue });
    setTab(newValue);
  };

  // ******************** toggle open handlers end ***************************

  // ******************** deafultTab start ***************************
  useEffect(() => {
    params.get("tab") ? setTab(params.get("tab")) : setTab("one");
  }, [params]);

  // ******************** deafultTab end ***************************

  return (
    <Box mx="20px">
      <InternalPageHeader
        onClick={() => {
          navigate(-1);
        }}
        title={id ? "Edit Product" : "Add Product"}
        sx={{ mb: 2 }}
      />
      <RequiredFieldLabel />
      {/* {singleLoader ? (
        <CircularProgress />
      ) : ( */}
      <>
        {value === "two" ? (
          <Grid container spacing={gridSpacing}>
            <>
              <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                <FormFields
                  files={files}
                  setFiles={setFiles}
                  singleUpdate={singleUpdate}
                  control={control}
                  errors={errors}
                  watch={watch}
                  reset={reset}
                  setValue={setValue}
                  setSpecificationsData={setSpecificationsData}
                  update={update}
                  brandsOptions={brandsOptions}
                  categoriesTreeList={categoriesTreeList}
                  setCategoryValue={setCategoryValue}
                  categoryValue={categoryValue}
                  setOpen={setOpen}
                  setBrandOpen={setBrandOpen}
                  getPCategories={getPCategories}
                  setIsSpecificationOpen={setIsSpecificationOpen}
                  isSpecificationOpen={isSpecificationOpen}
                  sliderFile={sliderFile}
                  setSliderFiles={setSliderFiles}
                />
              </Grid>
              <Grid item xl={9} lg={8} md={12} sm={12} xs={12}>
                <Card
                  sx={{
                    overflow: "visible !important",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                  }}
                >
                  <Box backgroundColor={colors.themeGray[100]} p={2}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <ProductTabs handleChange2={handleChange2} value={value} id={id} />
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Grid container spacing={gridSpacing}>
                            <SubProductTable
                              specificationsData={specificationsData}
                              setIsSpecificationOpen={setIsSpecificationOpen}
                              isSpecificationOpen={isSpecificationOpen}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            </>
          </Grid>
        ) : (
          <>
            {/* {singleLoader ? (
              <Box
                width="100%"
                textAlign="center"
                backgroundColor={colors.themeGray[100]}
                borderRadius={1}
                boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
                p={2}
                lineHeight={0}
              >
                <CircularProgress />
              </Box>
            ) : ( */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container spacing={gridSpacing} sx={{ mb: 2 }}>
                <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                  <FormFields
                    files={files}
                    setFiles={setFiles}
                    sliderFile={sliderFile}
                    setSliderFiles={setSliderFiles}
                    singleUpdate={singleUpdate}
                    control={control}
                    errors={errors}
                    watch={watch}
                    reset={reset}
                    setValue={setValue}
                    setSpecificationsData={setSpecificationsData}
                    update={update}
                    setUpdate={setUpdate}
                    brandsOptions={brandsOptions}
                    categoriesTreeList={categoriesTreeList}
                    setCategoryValue={setCategoryValue}
                    categoryValue={categoryValue}
                    setOpen={setOpen}
                    openBrand={openBrand}
                    setBrandOpen={setBrandOpen}
                    getPCategories={getPCategories}
                    addedBrand={newBrand}
                  />
                </Grid>

                <Grid item xl={9} lg={8} md={12} sm={12} xs={12}>
                  <Card
                    sx={{
                      overflow: "visible !important",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                    }}
                  >
                    <Box backgroundColor={colors.themeGray[100]} p={2}>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box>
                            <ProductTabs handleChange2={handleChange2} value={value} id={id} />
                          </Box>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Card sx={{ px: 2, pt: 2, overflow: "visible !important" }}>
                            <Grid container spacing={gridSpacing}>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h5" color={colors.secondary[900]}>
                                  {id ? "Edit Product" : "Create Product"}
                                </Typography>
                              </Grid>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormControl sx={{ width: "100%" }}>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    size="small"
                                    label={
                                      <p className="my-0">
                                        Product Name <span className="danger mr-0">*</span>
                                      </p>
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    {...register("name", { required: true, maxLength: 70 })}
                                  />

                                  {errors.name && errors.name.type === "required" && (
                                    <ErrorMSG text="Product Name is required" />
                                  )}
                                  {errors.name && errors.name.type === "maxLength" && (
                                    <ErrorMSG text="Product Name should not be greater than length 70" />
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <FormControl sx={{ width: "100%" }}>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    // size="small"
                                    label={
                                      <p className="my-0">
                                        Short Description <span className="danger mr-0">*</span>
                                      </p>
                                    }
                                    multiline
                                    rows={1}
                                    InputLabelProps={{ shrink: true }}
                                    {...register("shortDescription", { required: true, maxLength: 200 })}
                                  />
                                  {errors.shortDescription && errors.shortDescription.type === "required" && (
                                    <ErrorMSG text="Short Description is required" />
                                  )}
                                  {errors.shortDescription && errors.shortDescription.type === "maxLength" && (
                                    <ErrorMSG text="Short Description should not be greater than length 200" />
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <FormControl fullWidth>
                                  <label className="react-select-labels z-index-6">Select Stock Status</label>
                                  <Controller
                                    render={({ field: { onChange, value } }) => {
                                      return (
                                        <Select
                                          // size="small"
                                          labelId="demo-select-small-label"
                                          id="demo-select-small"
                                          value={value}
                                          onChange={(e) => onChange(e)}
                                          label="Stock Status"
                                          name="productStatus"
                                        >
                                          <MenuItem value="None">None</MenuItem>
                                          <MenuItem value="newArrival">New Arrival</MenuItem>
                                          <MenuItem value="bestSeller">Best Seller</MenuItem>
                                        </Select>
                                      );
                                    }}
                                    control={control}
                                    rules={{ required: false }}
                                    name={`productStatus`}
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" gap="4px">
                                  <Box display="flex" justifyContent="flex-start" alignItems="center" gap="4px">
                                    <Typography
                                      variant="h5"
                                      align="left"
                                      color={colors.secondary[900]}
                                      sx={{ fontWeight: "600", textDecoration: "underline" }}
                                    >
                                      Specification:
                                    </Typography>
                                    <Tooltip
                                      title={
                                        <>
                                          <span>
                                            Add specifications to provide detailed information about the product
                                            flavors, features, dimensions, nutrient content, and capabilities.
                                          </span>
                                        </>
                                      }
                                    >
                                      <ErrorOutlineIcon
                                        className="cursor-help"
                                        sx={{ fontSize: "16px", mt: "2px", color: "#f44666" }}
                                      />
                                    </Tooltip>
                                  </Box>
                                  {!fields.length > 0 && (
                                    <CustomIconButton
                                      bgColor={colors.themeGray[100]}
                                      onClick={() => {
                                        append({ name: "", value: "", type: "" });
                                      }}
                                      p="4px 4px 4px 8px"
                                      sx={{ fontSize: "16px", border: `1px solid ${colors.themeGray[600]}` }}
                                    >
                                      Add Specifications
                                      <AddIcon sx={{ fontSize: "18px", ml: "2px" }} />
                                    </CustomIconButton>
                                  )}
                                </Box>
                                {fields.map((field, index) => (
                                  <Box key={field.id}>
                                    <Box
                                      sx={{
                                        background: colors.themeGray[100],
                                        px: 1,
                                        pb: 1.5,
                                        pt: 2.5,
                                        mb: 1,
                                        mt: `${index === 0 ? "16px" : 0}`,
                                        borderRadius: "4px"
                                        // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px inset"
                                      }}
                                    >
                                      <Grid container spacing={2} alignItems="flex-start">
                                        <Grid item xl={4} lg={6} md={6} sm={6} xs={12}>
                                          <FormControl fullWidth>
                                            <Controller
                                              render={({ field: { onChange, value } }) => {
                                                return (
                                                  <Autocomplete
                                                    size="small"
                                                    value={value}
                                                    onChange={(event, newValue, clear) => {
                                                      if (clear === "clear") {
                                                        setValue(`specification[${index}].name`, "");
                                                      }
                                                      onChange(newValue);
                                                      if (newValue) {
                                                        let duplicateOption =
                                                          watch()?.specification.length > 0 &&
                                                          watch()
                                                            ?.specification.filter((o) => o.type !== "Other")
                                                            .filter((o) => o.type === newValue.label).length > 0;

                                                        if (!duplicateOption) {
                                                          onChange(newValue);
                                                        } else {
                                                          toast.error("Specification already added");
                                                        }
                                                        if (
                                                          watch()?.specification.length > 0 &&
                                                          watch()?.specification[index].type === "Other"
                                                        ) {
                                                          setValue(`specification[${index}].name`, "");
                                                        } else {
                                                          setValue(
                                                            `specification[${index}].name`,
                                                            watch()?.specification.length > 0 &&
                                                              watch()?.specification[index].type.label
                                                          );
                                                        }
                                                      }
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                      onChange(newInputValue);
                                                    }}
                                                    id="controllable-states-demo"
                                                    options={specificationTypes}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        className="autocompleteInput"
                                                        label="Option"
                                                        size="small"
                                                        InputLabelProps={{ shrink: true }}
                                                      />
                                                    )}
                                                  />
                                                );
                                              }}
                                              control={control}
                                              rules={{ required: true }}
                                              name={`specification.${index}.type`}
                                            />
                                            {errors.specification &&
                                              errors.specification[index] &&
                                              errors.specification[index].type &&
                                              errors?.specification[index]?.type?.type === "required" && (
                                                <ErrorMSG text="Option is required" />
                                              )}
                                          </FormControl>
                                        </Grid>
                                        <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                                          <FormControl fullWidth>
                                            <TextField
                                              fullWidth
                                              size="small"
                                              type="text"
                                              InputLabelProps={{ shrink: true }}
                                              label="Name"
                                              {...register(`specification.${index}.name`, {
                                                required: true
                                              })}
                                            />
                                            {errors.specification &&
                                              errors.specification[index] &&
                                              errors.specification[index].name &&
                                              errors.specification[index].name.type === "required" && (
                                                <ErrorMSG text="Name is required" />
                                              )}
                                          </FormControl>
                                        </Grid>
                                        <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                                          <FormControl fullWidth>
                                            <TextField
                                              fullWidth
                                              size="small"
                                              type="text"
                                              InputLabelProps={{ shrink: true }}
                                              label="Value"
                                              {...register(`specification.${index}.value`, {
                                                required: true
                                              })}
                                            />
                                            {errors.specification &&
                                              errors.specification[index] &&
                                              errors.specification[index].value &&
                                              errors.specification[index].value.type === "required" && (
                                                <ErrorMSG text="Value is required" />
                                              )}
                                          </FormControl>
                                        </Grid>
                                        <Grid item xl={2} lg={6} md={6} sm={6} xs={12}>
                                          <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            gap="8px"
                                            mt={isSmall ? 0 : 1}
                                          >
                                            <CustomIconButton
                                              onClick={() => {
                                                append({ name: "", value: "", type: "" });
                                              }}
                                              p="4px"
                                            >
                                              <AddIcon />
                                            </CustomIconButton>
                                            <CustomIconButton onClick={() => remove(index)} p="4px">
                                              <RemoveIcon />
                                            </CustomIconButton>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                ))}
                              </Grid>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormControl sx={{ width: "100%" }}>
                                  <FormLabel sx={{ mb: 1, color: `${colors.secondary[900]}` }}>
                                    Long Description
                                  </FormLabel>
                                  <Controller
                                    render={({ field: { onChange, value } }) => (
                                      <JoditEditor
                                        ref={editor}
                                        value={value}
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={(newContent) => {
                                          // setContent(newContent);
                                          onChange(newContent);
                                        }} // preferred to use only this option to update the content for performance reasons
                                        onChange={(newContent) => {
                                          // onChange(newContent);
                                        }}
                                      />
                                    )}
                                    control={control}
                                    name="longDescription"
                                  />
                                  {errors.longDescription && errors.longDescription.type === "required" && (
                                    <ErrorMSG text="Description is required" />
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Box className="stickyButton">
                              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} > */}

                              <Button
                                type="submit"
                                variant="contained"
                                disabled={modifyLoader}
                                sx={[
                                  {
                                    "&:hover": {
                                      bgcolor: colors.themeSecondaryBlueColor[100],
                                      color: colors.white[100]
                                    }
                                  },
                                  { bgcolor: colors.black[100] }
                                ]}
                              >
                                {modifyLoader ? "Saving..." : "Save & Next"}
                              </Button>

                              {/* </Grid> */}
                            </Box>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </form>
            {/* )} */}
          </>
        )}
        {open && (
          <CategoryAdd open={open} handleClose={handleClose} reqData={{}} rowData={{}} isCategoryFromProduct={true} />
        )}
        {openTax && <TaxAdd open={openTax} id={id} rowData={{}} handleClose={handleTaxClose} reqData={{}} />}
        {openBrand && (
          <BrandAddModal
            setBrandValue={setBrandValue}
            open={openBrand}
            isBrandFromProduct={true}
            id={id}
            rowData={{}}
            handleClose={handleBrandClose}
            reqData={{}}
            newBrand={newBrand}
            setNewBrand={setNewBrand}
          />
        )}
      </>
      {/* )} */}
    </Box>
  );
}
