import { useTheme } from "@emotion/react";
import React, { Fragment } from "react";
import { Button, CircularProgress } from "@mui/material";
import { tokens } from "../theme";

export const ButtonLoader = ({ text, disabled, loading, children, sx, full = true, ...rest }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const style = {
    gap: "10px",
    width: "100%",
    p: "0.5rem",
    backgroundColor: colors.primary[900],
    color: colors.white[900],
    border: 1,
    borderColor: colors.primary[900],
    "&:hover": {
      backgroundColor: colors.primary[900],
      color: colors.white[900],
      border: `1 solid ${colors.primary[900]}`
    },
    "&:focus": {
      backgroundColor: colors.primary[900],
      color: colors.white[900]
    }
  };

  return (
    <Button
      fullWidth={full}
      disabled={disabled || typeof loading === "string" ? Boolean(loading) : loading}
      sx={sx || style}
      {...rest}
    >
      {loading ? (
        <Fragment>
          {`${text} `} <CircularProgress sx={{ mr: 1 }} color="inherit" disableShrink size={20} />{" "}
        </Fragment>
      ) : (
        text
      )}
      {children}
    </Button>
  );
};
