import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import { BrandsMap } from "./type";

//fetch vendor
export const fetchBrands = (body) => async (dispatch) => {
  try {
    dispatch({ type: BrandsMap.BRAND_REQUEST });
    let apiEndpoint = `${API_END_POINTS.brands}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: BrandsMap.BRAND_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: BrandsMap.BRAND_FAIL, error: message });
  }
};
export const fetchBrandsExport = (body) => async (dispatch) => {
  try {
    let apiEndpoint = `${API_END_POINTS.brands}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return data;
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
  }
};

//Add vendor
export const createBrand = (body) => async (dispatch) => {
  try {
    dispatch({ type: BrandsMap.BRAND_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addBrand}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: BrandsMap.BRAND_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: BrandsMap.BRAND_ADD_FAIL, error: message });
  }
};

//Edit vendor
export const updateBrand = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: BrandsMap.BRAND_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editBrand}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: BrandsMap.BRAND_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: BrandsMap.BRAND_UPDATE_FAIL, error: message });
  }
};

//Delete vendor
export const deleteBrand = (id) => async (dispatch) => {
  try {
    dispatch({ type: BrandsMap.BRAND_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteBrand}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: BrandsMap.BRAND_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: BrandsMap.BRAND_DELETE_FAIL, error: message });
  }
};
