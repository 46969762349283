import React, { Fragment, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { convertToDollar, formatDate } from "../../../utils/custom";
import StatementAddModal from "../StatementAddModal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getStatements, payout, viewInvoiceStatements, viewPDFData, viewStatements } from "../redux/action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GetUserRole from "../../../utils/GetUserRole";
import DataNotFound from "../../../components/DataNotFound";
import MyTaxGridToolbar from "../filter/MyDriverGridToolbar";
import CustomTable from "../../../components/CustomTable";

export default function StatementsViewTable({
  statements,
  isLoading,
  currentStatementId,
  location,
  setReqData,
  reqData,
  total,
  setCurrentStatementId
}) {
  const [openModal, setOpenModal] = useState(false);
  const [storeName, setStoreName] = useState("");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const dispatch = useDispatch();
  const statemetPayment = GetUserRole("statement_payment");

  const printInvoiceData = async (pdfWindow, id) => {
    await dispatch(viewPDFData(id)).then(async (data) => {
      if (data && data?.payload?.status) {
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + data?.payload?.data + "'></iframe>");
        window.open(data?.payload?.data, "_blank");
      }
    });
  };

  const columns = () => {
    return [
      {
        title: "ID",
        flex: 1,
        width: 100,
        render: (row) => (
          <Typography color={colors.secondary[900]} fontWeight="bold">
            {row.id}
          </Typography>
        )
      },
      {
        title: "Statement Id",
        minWidth: 150,
        flex: 1,
        render: (row) => (
          <Typography
            color={colors.secondary[900]}
            fontWeight="bold"
            sx={{ cursor: "pointer", width: "fit-content" }}
            onClick={() => {
              setOpenModal(true);
              setStoreName(row.storeName);
              dispatch(viewInvoiceStatements({ itemId: row._id })).then(async (data) => {
                if (data && data?.payload?.status) {
                  toast.success(data.payload.message);
                }
              });
            }}
          >
            {row.invoiceNo}
          </Typography>
        )
      },
      {
        title: "Generated Date",
        minWidth: 150,
        flex: 1,
        render: (row) => (
          <Typography sx={{ textTransform: "capitalize" }}>
            {row?.createdDate && formatDate(row.createdDate)}
          </Typography>
        )
      },
      // {
      //   title: "Location Name",
      //   render: (row) => <Typography sx={{ textTransform: "capitalize" }}>{row.storeName}</Typography>,
      //   hideable: !location
      // },
      {
        title: "Status",
        flex: 1,
        render: (row) => {
          return (
            <>
              {row.status === "paid" || !statemetPayment ? (
                <Typography className="text-capitalize">{row.status}</Typography>
              ) : (
                <Select
                  value={row.status}
                  onChange={(e) => {
                    dispatch(
                      payout({
                        itemId: row._id
                      })
                    ).then(async (data) => {
                      if (data && data?.payload?.status) {
                        toast.success(data.payload.message);
                        await dispatch(viewStatements({ statementId: currentStatementId && currentStatementId }));
                        // await dispatch(getStatements(reqData));
                      } else {
                        toast.error(data.error);
                      }
                    });
                  }}
                  className={`orderTableSelect${row.id}`}
                >
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"paid"}>Paid</MenuItem>
                </Select>
              )}
            </>
          );
        }
      },
      // {
      //   title: "Total Admin Comission",
      //   render: (row) => (
      //     <Typography sx={{ textTransform: "capitalize" }}>
      //       {row.totalAdminComm ? convertToDollar(row.totalAdminComm) : "$0.00"}
      //     </Typography>
      //   )
      // },
      {
        title: "Total Location Amount",
        minWidth: 150,
        flex: 1,
        render: (row) => (
          <Typography sx={{ textTransform: "capitalize" }}>
            {row.totalLocationAmount ? convertToDollar(row.totalLocationAmount.toFixed(2)) : "$0.00"}
          </Typography>
        )
      },
      {
        title: "Total Amount",
        minWidth: 150,
        flex: 1,
        render: (row) => (
          <Typography sx={{ textTransform: "capitalize" }}>
            {row.totalAmount ? convertToDollar(row.totalAmount.toFixed(2)) : "$0.00"}
          </Typography>
        )
      },
      {
        sticky: true,
        title: "PDF",
        minWidth: 100,
        flex: 1,
        render: (row) => (
          <span>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                var pdfWindow = window.open("", "_blank");
                printInvoiceData(pdfWindow, row.invoiceNo);
              }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </span>
        )
      }
    ];
  };
  return (
    <Fragment>
      {!location ? (
        <TableContainer sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader aria-label="sticky table" sx={{ borderBottom: "1px solid #ddd" }}>
            <TableHead sx={{ textWrap: "nowrap" }}>
              <TableRow>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}>
                  <Typography>ID</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Statement Id</Typography>
                </TableCell>
                {!location && (
                  <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                    <Typography>Location Name</Typography>
                  </TableCell>
                )}
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Status</Typography>
                </TableCell>
                {!location && (
                  <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                    <Typography>Total Admin Comission</Typography>
                  </TableCell>
                )}
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Total Location Amount</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Total Amount</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}>
                  <Typography>PDF</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements && statements.length > 0 ? (
                statements.map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color={colors.secondary[900]}
                        fontWeight="bold"
                        sx={{ cursor: "pointer", width: "fit-content" }}
                        onClick={() => {
                          setOpenModal(true);
                          setStoreName(row.storeName);
                          dispatch(viewInvoiceStatements({ itemId: row._id })).then(async (data) => {
                            if (data && data?.payload?.status) {
                              toast.success(data.payload.message);
                            }
                          });
                        }}
                      >
                        {row.invoiceNo}
                      </Typography>
                    </TableCell>

                    {!location && (
                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>{row.storeName}</Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <>
                        {row.status === "paid" || !statemetPayment ? (
                          <Typography className="text-capitalize">{row.status}</Typography>
                        ) : (
                          <Select
                            value={row.status}
                            onChange={(e) => {
                              dispatch(
                                payout({
                                  itemId: row._id
                                })
                              ).then(async (data) => {
                                if (data && data?.payload?.status) {
                                  toast.success(data.payload.message);
                                  await dispatch(
                                    viewStatements({ statementId: currentStatementId && currentStatementId })
                                  );
                                  await dispatch(getStatements(reqData));
                                  setCurrentStatementId("");
                                } else {
                                  toast.error(data.error);
                                }
                              });
                            }}
                            className={`orderTableSelect${index}`}
                          >
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"paid"}>Paid</MenuItem>
                          </Select>
                        )}
                      </>
                    </TableCell>
                    {!location && (
                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {row.totalAdminComm ? convertToDollar(row.totalAdminComm) : "$0.00"}
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell>
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {row.totalLocationAmount ? convertToDollar(row.totalLocationAmount) : "$0.00"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {row.totalAmount ? convertToDollar(row.totalAmount) : "$0.00"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <span>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            var pdfWindow = window.open("", "_blank");
                            printInvoiceData(pdfWindow, row.invoiceNo);
                          }}
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} sx={{ p: 0 }}>
                    <DataNotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box width="100%" className="pageMainCardBox" textAlign="center">
          <Grid container spacing={gridSpacing}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", gap: "5px", mb: 1 }}>
              <MyTaxGridToolbar reqData={reqData} setReqData={setReqData} />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomTable
                tableHeight="200px"
                cols={columns()}
                data={
                  statements?.records && statements?.records?.length > 0
                    ? statements?.records?.map((statement, i) => {
                        return {
                          id: i + 1,
                          ...statement
                        };
                      })
                    : []
                }
                total={total || 0}
                isLoading={isLoading}
                isOnlyTable
                dataFilter={reqData}
                setDataFilter={setReqData}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {openModal && <StatementAddModal openModal={openModal} setOpenModal={setOpenModal} storeName={storeName} />}
    </Fragment>
  );
}
