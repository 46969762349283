import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

const TableNodata = ({ colSpan = 0, text = "Data not available!" }) => {
  return (
    <TableRow>
      <TableCell sx={{ border: "none" }} colSpan={colSpan} align="center">
        <Typography variant="h5">{text || "Data not available!"}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default TableNodata;
