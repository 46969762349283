import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import { CategoryMap } from "./type";

// fetch categories
export const fetchCategories = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.CATEGORY_REQUEST });
    let apiEndpoint = `${API_END_POINTS.categories}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.GET_CATEGORIES,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: CategoryMap.CATEGORY_FAIL, error: message });
  }
};
export const fetchCategoriesExport = (body) => async (dispatch) => {
  try {
    let apiEndpoint = `${API_END_POINTS.categories}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return data;
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return message;
  }
};

// fetch categories
export const fetchSingleCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.SINGLE_CATEGORY_REQUEST });
    let apiEndpoint = `${API_END_POINTS.fetchSingleCategory}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${id}`);
    return dispatch({
      type: CategoryMap.SINGLE_CATEGORY_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: CategoryMap.SINGLE_CATEGORY_FAIL, error: message });
  }
};

// fetch categories tree
export const getCategoryTree = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.CATEGORY_TREE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.treeCategory}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);

    return dispatch({
      type: CategoryMap.CATEGORY_TREE_SUCCESS,
      payload: { ...data, ...body }
    });
  } catch (error) {
    const message = error && error?.data?.message ? error.data?.message : error?.message;
    // toast.error(message);
    dispatch({ type: CategoryMap.CATEGORY_TREE_FAIL, error: message });
  }
};

// add category
export const addCategory = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.CATEGORY_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addCategory}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.CATEGORY_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: CategoryMap.CATEGORY_ADD_FAIL, error: message });
  }
};

// Update category
export const updateCategory = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.CATEGORY_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editCategory}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: CategoryMap.CATEGORY_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: CategoryMap.CATEGORY_UPDATE_FAIL, error: message });
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.CATEGORY_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteCategoryEndpoint}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: CategoryMap.CATEGORY_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: CategoryMap.CATEGORY_DELETE_FAIL, error: message });
  }
};
export const updateStatus = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.STATUS_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editStatusEndpoint}`;
    const { data } = await JsonInterceptor.put(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.STATUS_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: CategoryMap.STATUS_UPDATE_FAIL, error: message });
  }
};

export const updateCategoryStatus = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.STATUS_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.updateCategoryStatus}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.STATUS_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: CategoryMap.STATUS_UPDATE_FAIL, error: message });
  }
};

export const updateReturnCategoryStatus = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.UPDATE_CATEGORY_RETURN_STATUS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.updateReturnStatusCategory}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.UPDATE_CATEGORY_RETURN_STATUS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: CategoryMap.UPDATE_CATEGORY_RETURN_STATUS_FAIL, error: message });
  }
};

// fetch specifications option tree
export const getSpecificationsList = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.SPECIFICATIONS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getSpecifications}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.SPECIFICATIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: CategoryMap.SPECIFICATIONS_FAIL, error: message });
  }
};

// fetch categories tree
export const getCategoryTaxTree = (body) => async (dispatch) => {
  try {
    dispatch({ type: CategoryMap.CATEGORY_TAX_TREE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getCategoryTaxTreeEndpoint}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: CategoryMap.CATEGORY_TAX_TREE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: CategoryMap.CATEGORY_TAX_TREE_FAIL, error: message });
  }
};
