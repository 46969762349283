import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import { DotMenu } from "./icons";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

const DropdownMenu = ({ options, buttonLabel = "Options", icon = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {icon ? (
        <IconButton
          id="custom-menu-button"
          aria-controls={open ? "custom-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
        >
          <DotMenu width="20px" height="20px" size="small" />
        </IconButton>
      ) : (
        <Button
          id="custom-menu-button"
          aria-controls={open ? "custom-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {buttonLabel}
        </Button>
      )}
      <StyledMenu
        id="custom-menu"
        MenuListProps={{
          "aria-labelledby": "custom-menu-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <React.Fragment key={index}>
            {option.divider ? (
              <Divider sx={{ my: 0.5 }} />
            ) : (
              <MenuItem
                sx={option?.style || null}
                onClick={() => {
                  handleClose();
                  option.onClick && option.onClick();
                }}
                disabled={option.disabled}
                disableRipple
              >
                {option.icon && React.cloneElement(option.icon, { style: { marginRight: "1rem" } })}
                {option.label}
              </MenuItem>
            )}
          </React.Fragment>
        ))}
      </StyledMenu>
    </div>
  );
};

export default DropdownMenu;
