import React from "react";
import Box from "@mui/material/Box";
import {
  Badge,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { styles } from "../../../utils/custom";
import { tokens } from "../../../theme";
import { toast } from "react-toastify";

export default function MyFilter({
  reqData,
  setReqData,
  payLoadData,
  setPayloadData,
  dateRange,
  setDateRange,
  selectFilter,
  setSelectFilter,
  clearFilterdata,
  props
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;

  // toggel drawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (event) {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }
    }
    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  //dates change handler
  const onChange = (dates) => {
    const [start, end] = dates;

    setDateRange(
      Object.assign({}, dateRange, {
        startDate: start,
        endDate: end,
        dateRange: 1
      })
    );
    setPayloadData(
      Object.assign({}, payLoadData, {
        fromDate: start,
        toDate: end,
        dateRange: 1
      })
    );
  };

  // handle change
  const handleFilterChange = (e) => {
    setPayloadData(Object.assign({}, payLoadData, { [e.name]: e.value }));
  };

  // status options
  const statusOptions = [
    { label: "Active", value: "true", name: "status" },
    { label: "Inactive", value: "false", name: "status" }
  ];

  // submit handler
  const submitFilterdata = (e) => {
    if (!payLoadData.toDate) {
      e.preventDefault();
      toast.error("Please select end date");
    } else {
      e.preventDefault();
      setReqData(payLoadData);
      // props.restorePaginationState();
      let data = selectFilter;
      if (payLoadData.status !== "" && data.indexOf("Status") === -1) data.push("Status");
      if (payLoadData.dateRange !== 0 && data.indexOf("Date") === -1) {
        data.push("Date");
      }
      setSelectFilter(data);
      setState({ ...state, right: false });
    }
  };
  const list = (anchor) => (
    <Box
      id="notificationDrawer"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }}
      role="presentation"
      className="adminDrawer"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="6px" p="16px">
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap="6px">
          <FilterAltIcon sx={{ color: colors.greenAccent[500] }} />
          <Typography variant="h4" sx={{ fontWeight: "600", color: colors.greenAccent[500] }}>
            Filter
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setState({ ...state, right: false });
            // clearFilterdata("clearall");
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box></Box>
      <Box p="16px">
        <form autoComplete="off" onSubmit={submitFilterdata}>
          <Grid container spacing={gridSpacing}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="custom-desgin">
                  <FormControl fullWidth>
                    <label className="react-select-labels z-index-8">Created Date</label>
                    <DatePicker
                      className="datePickerStyling-1 z-index-7"
                      selected={dateRange.startDate}
                      onChange={onChange}
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      selectsRange
                      // inline
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-4">Status</label>
                <Select
                  styles={styles}
                  theme={(x) => ({ ...x, borderRadius: 4 })}
                  className="react-select-no-outline reactSelectFocus z-index-3"
                  classNamePrefix="react-select"
                  isClearable={false}
                  placeholder="Select Status"
                  options={statusOptions}
                  value={statusOptions.find((x) => x.value === payLoadData.status)}
                  onChange={(e) => handleFilterChange(e)}
                  isSearchable={false}
                  name="status"
                />
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={() => {
                  clearFilterdata("clearall");
                  setState({ ...state, right: false });
                }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={(e) => {
                  submitFilterdata(e);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
  return (
    <>
      <Button onClick={toggleDrawer("right", true)} sx={{ p: "4px 5px" }}>
        {selectFilter.length > 0 ? (
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            className="notificationBadge"
          >
            <FilterListIcon sx={{ mr: 0.5, fontSize: "20px" }} />
          </Badge>
        ) : (
          <FilterListIcon sx={{ mr: 0.5, fontSize: "20px" }} />
        )}
        Filters
      </Button>
      <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
        {list("right")}
      </Drawer>
    </>
  );
}
