import { LinearProgress, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import TableNodata from "./TableNodata";

const TableData = ({ data = [], cols = [], noDataText, loading }) => {
  return (
    <TableBody sx={{ position: "relative" }}>
      {loading && (
        <TableRow>
          <TableCell colSpan={cols?.length} sx={{ padding: 0, borderBottom: "none", position: "relative" }}>
            <div style={{ position: "absolute", top: "-1px", left: 0, width: "100%" }}>
              <LinearProgress />
            </div>
          </TableCell>
        </TableRow>
      )}
      {data?.length > 0
        ? data?.map((item, index) => (
            <TableRow key={index} sx={{ backgroundColor: "#e9ecef" }}>
              {cols
                ?.filter((x) => !x.hide)
                ?.map((col, key) => (
                  <TableCell
                    key={key}
                    className={col?.sticky ? "sticky-value-column" : ""}
                    sx={{ textWrap: "nowrap", padding: "2px 16px", lineHeight: "1.32" }}
                  >
                    {col.render(item)}
                  </TableCell>
                ))}
            </TableRow>
          ))
        : !loading && <TableNodata colSpan={cols?.length} text={noDataText} />}
    </TableBody>
  );
};

export default TableData;
